import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { Role } from "../types";
import { GetRolePayload } from "../types/role";

export const roleApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getRoles: builder.query<Role[], void>({
      query: () => `/provisioner/roles`,
    }),

    getRoleById: builder.query<Role, GetRolePayload>({
      query: ({id}) => `/provisioner/roles/${id}`,
    }),

    createRole: builder.mutation<Role, Partial<Role>>({
      query: (newRole) => ({
        url: `/provisioner/roles`,
        method: 'POST',
        body: newRole,
      }),
    }),

    updateRole: builder.mutation<Role, Partial<Role>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/roles/${id}`,
        method: 'PUT',
        body: update,
      }),
    }),

    deleteRole: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/roles/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation
} = roleApi;
