import AddUpdatePermission from "../pages/add_update";
import { RouteObject } from "../../../common/types";
import PermissionList from "../pages";


const permissionRoutes: Array<RouteObject> = [
  {
    path: '/apps-permissions-list',
    component: PermissionList,
    children: [
      {
        path: 'add',
        component: AddUpdatePermission
      },
      {
        path: 'update/:id',
        component: AddUpdatePermission
      },
    ]
  },
];

export default permissionRoutes;
