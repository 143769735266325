import OfficeList from "../pages";
import AddUpdateOffice from "../pages/add_update";
import { RouteObject } from "../../../common/types";

const officeRoutes: Array<RouteObject> = [
  {
    path: '/apps-offices-list',
    component: OfficeList,
    children: [
      {
        path: 'add',
        component: AddUpdateOffice
      },
      {
        path: 'update/:id',
        component: AddUpdateOffice
      },
    ]
  },
];

export default officeRoutes;
