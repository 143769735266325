import TenantList from "../pages";
import AddUpdateTenant from "../pages/add_update";
import { RouteObject } from "../../../common/types";

const tenantRoutes: Array<RouteObject> = [
  {
    path: '/apps-tenants-list',
    component: TenantList,
    children: [
      {
        path: 'add',
        component: AddUpdateTenant
      },
      {
        path: 'update/:id',
        component: AddUpdateTenant
      },
    ]
  },
];

export default tenantRoutes;
