import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { WorkHistory } from "../types";
import { EmploymentTypes } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  workHistory?: WorkHistory;
  onClose: () => void;
  onSubmit: (data: Partial<WorkHistory>) => void;
}

/**
 * @function
 * @name AddUpdateWorkHistory
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateWorkHistory: React.FC<AddUpdateProps> = ({isOpen, workHistory, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<WorkHistory>>(workHistory || {});

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      institutionName: eventData?.institutionName ?? '',
      position: eventData?.position ?? '',
      supervisor: eventData?.supervisor ?? '',
      employmentType: eventData?.employmentType ?? '',
      address: eventData?.address ?? '',
      startDate: eventData?.startDate ?? '',
      endDate: eventData?.endDate ?? '',
    },
    validationSchema: Yup.object({
      institutionName: Yup.string().required("Please Enter Institution Name"),
      position: Yup.string().required("Please Enter Position"),
      address: Yup.string().required("Please Enter Address"),
      supervisor: Yup.string().required("Please Enter Supervisor Name"),
      startDate: Yup.string().required("Please Enter Start Date"),
      endData: Yup.string().optional(),
    }),
    onSubmit: (
      values: Partial<WorkHistory>,
      {setSubmitting}: FormikHelpers<Partial<WorkHistory>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {workHistory ? "Edit WorkHistory" : "Add WorkHistory"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="workHistoryInstituionNameInput"
                     className="inline-block mb-2 text-base font-medium">
                Institution Name <span className="text-red-500">*</span>
              </label>
              <input type="text" id="workHistoryInstituionNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Instituion name"
                     name="institutionName"
                     onChange={validation.handleChange}
                     value={validation.values.institutionName || ""}
              />
              {validation.touched.institutionName && validation.errors.institutionName ? (
                <p className="text-red-400">{validation.errors.institutionName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="workHistoryPositionInput"
                     className="inline-block mb-2 text-base font-medium">
                Position <span className="text-red-500">*</span>
              </label>
              <input type="text" id="workHistoryPositionInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Position"
                     name="position"
                     onChange={validation.handleChange}
                     value={validation.values.position || ""}
              />
              {validation.touched.position && validation.errors.position ? (
                <p className="text-red-400">{validation.errors.position}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="workHistorySupervisorInput"
                     className="inline-block mb-2 text-base font-medium">
                Supervisor <span className="text-red-500">*</span>
              </label>
              <input type="text" id="workHistorySupervisorInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Supervisor"
                     name="supervisor"
                     onChange={validation.handleChange}
                     value={validation.values.supervisor || ""}
              />
              {validation.touched.supervisor && validation.errors.supervisor ? (
                <p className="text-red-400">{validation.errors.supervisor}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="workHistoryAddressInput"
                     className="inline-block mb-2 text-base font-medium">
                Address <span className="text-red-500">*</span>
              </label>
              <input type="text" id="workHistoryAddressInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Uhuru St"
                     name="address"
                     onChange={validation.handleChange}
                     value={validation.values.address || ""}
              />
              {validation.touched.address && validation.errors.address ? (
                <p className="text-red-400">{validation.errors.address}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="startDate" className="inline-block mb-2 text-base font-medium">
                Start Date <span className="text-red-500">*</span>
              </label>
              <input type="date"
                     id="startDate"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select Start Date"
                     name="startDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "startDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.startDate = event.target.value
                     }}
                     value={validation.values.startDate || ""}
              />
              {validation.touched.startDate && validation.errors.startDate ? (
                <p className="text-red-400">{validation.errors.startDate}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="endDate" className="inline-block mb-2 text-base font-medium">End Date</label>
              <input type="date"
                     id="endDate"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select End Date"
                     name="endDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "endDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.endDate = event.target.value
                     }}
                     value={validation.values.endDate || ""}
              />
              {validation.touched.endDate && validation.errors.endDate ? (
                <p className="text-red-400">{validation.errors.endDate}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employmentTypeSelect"
                     className="inline-block mb-2 text-base font-medium">Employment Type</label>
              <Select
                options={EmploymentTypes}
                id="employmentTypeSelect"
                name="employmentType"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "employmentType",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.employmentType && validation.errors.employmentType ? (
                <p className="text-red-400">{validation.errors.employmentType}</p>
              ) : null}
            </div>

          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addWorkHistoryModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {workHistory ? "Update WorkHistory" : "Add WorkHistory"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateWorkHistory;
