import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from "../../helpers/utils/baseUrl";

export const rtkBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const authUser: any = localStorage.getItem("authUser")
  const accessToken = JSON.parse(authUser) ? JSON.parse(authUser).accessToken : null;

  console.log("Args: ", args)

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  });

  const url = typeof args === 'string' ? args : args.url;

  const rtkResponse: any = await rawBaseQuery(args, api, extraOptions);

  if (url.includes("logout")) {
    return rtkResponse; // Return the raw response
  }

  if (rtkResponse?.data) {
    const {respCode, body, message} = rtkResponse.data;

    if (respCode === 600) {
      return {data: body};
    } else {
      console.log("Failed for a reason: ", {respCode, body, message});
      return {
        error: {
          status: respCode,
          data: {
            message: `Failed - ${respCode}: ${message}`
          },
        },
      };
    }
  }

  if (rtkResponse.error) {
    // Ensure meaningful error messages for fetch errors
    console.log('Error occurred', rtkResponse.error);
    return {
      error: {
        status: rtkResponse.error.status,
        data: {
          message: `Network Error: ${rtkResponse.error.data?.message || 'Unknown error'}`
        },
      },
    };
  }

  return rtkResponse;
};
