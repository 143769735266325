import React, { useMemo, useState } from 'react';
import BreadCrumb from 'common/BreadCrumb';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import TableContainer from 'common/TableContainer';
import { CheckCircle, Eye, Loader, Plus, Search, Trash2, X } from 'lucide-react';
import DeleteModal from 'common/DeleteModal';
import { toast } from 'react-toastify';
import WpLoader from "../../../common/components/loader";
import { Employee } from "../types";
import {
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeesQuery
} from "../slices/employee_slice_api";
import AddUpdateEmployee from "./add_update";

/**
 * @function
 * @name EmployeeList
 * @description Employee implementation
 * @version 0.1.0
 * @since 0.1.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const EmployeeList = () => {
  const navigate = useNavigate();
  const {data: employeesList = [], isLoading, refetch} = useGetEmployeesQuery();
  const [createEmployee] = useCreateEmployeeMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);

  const handleCreate = () => {
    setSelectedEmployee(undefined);
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    deleteEmployee(selectedEmployee!.id);
    setDeleteModal(false);
  };

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setSelectedEmployee(cell);
    }
  };

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ['name', 'status'];
    // filterDataBySearch(employees, search, keysToSearch, setEmployee);
  };

  // columns
  const Status = ({item}: any) => {
    switch (item) {
      case "active":
        return (<span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      case "draft":
        return (<span
          className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"><Loader
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      case "pendingApproval":
        return (<span
          className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      default:
        return <span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>;
    }
  };

  const columns = useMemo(() => [
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
      },
      {
        header: "First Name",
        accessorKey: "firstName",
        enableColumnFilter: false,
      },
      {
        header: "Sir Name",
        accessorKey: "sirName",
        enableColumnFilter: false
      },
      {
        header: "Other Name",
        accessorKey: "otherNames",
        enableColumnFilter: false
      },
      {
        header: "Employee ID",
        accessorKey: "employeeId",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link to="#!"
                className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
      },
      {
        header: "Job Title",
        accessorKey: "jobTitle",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-employees-view/${cell.cell.row.original.id}`}
            >
              <Eye className="inline-block size-3"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to="#!" onClick={() => {
              const orderData = cell.row.original;
              onClickDelete(orderData);
            }}>
              <Trash2 className="text-red-400 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status' || selectedOption.value === 'hidden') {
      // setEmployee(employees);
    }
    // else {
    //     const filteredEmployees = employees.filter((data: any) => data.status === selectedOption.value);
    //     setEmployee(filteredEmployees);
    // }
    // TODO Add implementation for fetching using the redux
  };

  const handleSubmit = async (data: Partial<Employee>) => {
    try {
      const createdEmployee = await createEmployee(data).unwrap();
      setIsModalOpen(false);
      toast.success(`Employee successfully created`);
      navigate(`/apps-employees-view/${createdEmployee.id}`);
    } catch (error: any) {
      toast.error(`Failed to ${selectedEmployee ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };

  function retrieveData() {
    return employeesList && employeesList.length > 0 ? (
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(employeesList || [])}
        customPageSize={10}
        divclassName="-mx-5 overflow-x-auto"
        tableclassName="w-full whitespace-nowrap"
        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
      />
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Outlet/>
      <BreadCrumb title='Employee List' pageTitle='Employees Management'/>
      <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
      <div className="card" id="employeeTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Employee (<b className="total-Employs">{employeesList?.length ?? 0}</b>)
            </h6>
            <div className="shrink-0">
              <Link data-modal-target="defaultModal"
                    className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                    to="/apps-employees-list/add">
                <button type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleCreate}
                >
                  <Plus className="inline-block size-2"/>
                  <span className="align-middle">Add Employee</span>
                </button>
              </Link>
            </div>
          </div>
          {isLoading ? <WpLoader/> : retrieveData()}
        </div>
      </div>

      <AddUpdateEmployee
        key={selectedEmployee?.id ?? 'new'}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedEmployee(undefined);
          navigate('.')
        }}
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

export default EmployeeList;
