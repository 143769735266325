import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ContactInfo } from "../types";
import { ContactTypes } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  contactInfo?: ContactInfo;
  onClose: () => void;
  onSubmit: (data: Partial<ContactInfo>) => void;
}

/**
 * @function
 * @name AddUpdateContact
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateContact: React.FC<AddUpdateProps> = ({isOpen, contactInfo, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<ContactInfo>>(contactInfo || {});

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: eventData?.type ?? '',
      value: eventData?.value ?? '',
      emergency: eventData?.emergency ?? false,
      status: eventData?.status ?? '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Select Type"),
      value: Yup.string().required("Please Enter Value"),
      emergency: Yup.string().required("Please Select Emergency"),
    }),
    onSubmit: (
      values: Partial<ContactInfo>,
      {setSubmitting}: FormikHelpers<Partial<ContactInfo>>
    ) => {
      console.log("Submitted values", values);
      onSubmit(values);
      setSubmitting(false);
    },
  });

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {contactInfo ? "Edit Contact" : "Add Contact"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="typeSelect"
                     className="inline-block mb-2 text-base font-medium">
                Type
              </label>
              <Select
                options={ContactTypes}
                id="typeSelect"
                name="type"
                value={ContactTypes.find(option => option.value.toLowerCase() === validation.values.type)}
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "type",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.type && validation.errors.type ? (
                <p className="text-red-400">{validation.errors.type}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="valueInput"
                     className="inline-block mb-2 text-base font-medium">
                Value
              </label>
              <input type="text" id="valueInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Value"
                     name="value"
                     onChange={validation.handleChange}
                     value={validation.values.value || ""}
              />
              {validation.touched.value && validation.errors.value ? (
                <p className="text-red-400">{validation.errors.value}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <div className="flex items-center">
                <div
                  className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                  <input type="checkbox" name="emergency" id="emergencyCheckbox"
                         className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-sm appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-red-500 dark:checked:border-red-500 arrow-none"
                         defaultChecked={false} onChange={validation.handleChange}
                         value={validation.values.emergency}
                  />
                  <label htmlFor="emergencyCheckbox"
                         aria-labelledby="emergencyCheckbox"
                         className="block h-5 overflow-hidden duration-300 ease-linear border rounded-sm cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-red-500 peer-checked/published:border-red-500"></label>
                </div>
                <label htmlFor="emergency" className="inline-block text-base font-medium cursor-pointer">
                  Is Emergency?
                </label>
              </div>
            </div>

          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addContactInfoModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {contactInfo ? "Update Contact" : "Add Contact"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateContact;
