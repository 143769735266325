import RoleList from "../pages";
import AddUpdateRole from "../pages/add_update";
import { RouteObject } from "../../../common/types";

const roleRoutes: Array<RouteObject> = [
  {
    path: '/apps-roles-list',
    component: RoleList,
    children: [
      {
        path: 'add',
        component: AddUpdateRole
      },
      {
        path: 'update/:id',
        component: AddUpdateRole
      },
    ]
  },
];

export default roleRoutes;
