import { RouteObject } from "../../../common/types";
import BasicResetPassword from "../../../pages/AuthenticationInner/ResetPassword/Basic";
import BasicTwoSteps from "../../../pages/AuthenticationInner/TwoSteps/Basic";
import BasicEmailVerify from "../../../pages/AuthenticationInner/VerifyEmail/Basic";
import Login from "../pages/login";


const authRoutes: Array<RouteObject> = [
  {
    path: "/login", component: Login
  },
  {
    path: "/reset-password",
    component: BasicResetPassword
  },
  {
    path: "/2fa",
    component: BasicTwoSteps
  },
  {
    path: "/auth-verify-email-basic",
    component: BasicEmailVerify
  },
];

export default authRoutes;