import { configureStore } from "@reduxjs/toolkit";
import { tenantApi } from "../features/tenant/slices/tenant_api_slice";
import { authApi } from "../features/auth/slices/auth_slice_api";
import LayoutReducer from "../features/layout/slices/reducer";
import ProfileReducer from "../features/profile/slices/reducer";
import { userApi } from "../features/user/slices/user_api_slice";
import { roleApi } from "../features/role/slices/role_slice_api";
import { projectApi } from "../features/project/slices/project_slice_api";
import { permissionApi } from "../features/permission/slices/permission_slice_api";
import { officeApi } from "../features/office/slices/office_slice_api";
import { employeeApi } from "../features/employee/slices/employee_slice_api";
import { jobCategoryApi } from "../features/job-category/slices/job_category_slice_api";
import { jobTitleApi } from "../features/job-title/slices/job_title_slice_api";

export const store = configureStore({
  reducer: {
    Layout: LayoutReducer,
    Profile: ProfileReducer,

    [authApi.reducerPath]: authApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [officeApi.reducerPath]: officeApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [jobCategoryApi.reducerPath]: jobCategoryApi.reducer,
    [jobTitleApi.reducerPath]: jobTitleApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(tenantApi.middleware)
      .concat(userApi.middleware)
      .concat(roleApi.middleware)
      .concat(projectApi.middleware)
      .concat(permissionApi.middleware)
      .concat(officeApi.middleware)
      .concat(jobCategoryApi.middleware)
      .concat(jobTitleApi.middleware)
      .concat(employeeApi.middleware);
  }
});


export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.dispatch>;
