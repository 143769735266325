import React, { ChangeEvent } from "react";
import { BadgeCheck, ImagePlus, Mail, MapPin, UserCircle } from "lucide-react";

// IMage
import avatar1 from "assets/images/users/avatar-1.png";
import { Employee } from "../types";
import { Outlet } from "react-router-dom";

interface AccountInfoProps {
  employee?: Employee;
  className?: string;
}

const AccountInfo: React.FC<AccountInfoProps> = ({className, employee}) => {

  const [selectedImage, setSelectedImage] = React.useState<string | ArrayBuffer | null>(avatar1);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <React.Fragment>
      <Outlet/>

      <div className={className}>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 2xl:grid-cols-12">
          <div className="lg:col-span-2 2xl:col-span-1">
            <div
              className="relative inline-block size-20 rounded-full shadow-md bg-slate-100 profile-user xl:size-28">
              <img src={selectedImage?.toString() ?? avatar1} alt=""
                   className="object-cover border-0 rounded-full img-thumbnail user-profile-image"/>
              <div
                className="absolute bottom-0 flex items-center justify-center size-8 rounded-full ltr:right-0 rtl:left-0 profile-photo-edit">
                <input id="profile-img-file-input" type="file"
                       className="hidden profile-img-file-input"
                       onChange={handleImageChange}/>
                <label htmlFor="profile-img-file-input"
                       className="flex items-center justify-center size-8 bg-white rounded-full shadow-lg cursor-pointer dark:bg-zink-600 profile-photo-edit">
                  <ImagePlus
                    className="size-4 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></ImagePlus>
                </label>
              </div>
            </div>
          </div>

          <div className="lg:col-span-10 2xl:col-span-9">
            <h5 className="mb-1">{employee?.firstName + ' ' + employee?.sirName} <BadgeCheck
              className="inline-block size-4 text-sky-500 fill-sky-100 dark:fill-custom-500/20"></BadgeCheck>
            </h5>
            <div className="flex gap-3 mb-4">
              <p className="text-slate-500 dark:text-zink-200">
                <UserCircle
                  className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle>
                {employee?.jobTitle ?? 'N/A'}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                <MapPin
                  className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500">
                </MapPin> {employee?.addresses?.at(0)?.type ?? 'N/A'}
              </p>
            </div>
          </div>
          {employee?.sirName && <div className="lg:col-span-12 2xl:col-span-2">
            <div className="flex gap-2 2xl:justify-end">
              <a href={`mailto:${employee?.sirName}`}
                 className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20">
                <Mail className="size-4"></Mail>
              </a>
            </div>
          </div>}
        </div>
      </div>
    </React.Fragment>

  );
}

export default AccountInfo;