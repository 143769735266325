import AddUpdateEmployee from "../pages/add_update";
import EmployeeList from "../pages";
import EmployeeAccount from "../components";
import { RouteObject } from "../../../common/types";
import AddUpdateContact from "../pages/contact_add_update";
import AddUpdateAddress from "../pages/address_add_update";
import AddUpdateAccount from "../pages/account_add_update";
import AddUpdateBeneficiary from "../pages/beneficiary_add_update";
import AddUpdateWorkHistory from "../pages/work_history_add_update";
import AddUpdateEducation from "../pages/education_add_update";
import AddUpdateReferee from "../pages/referee_add_update";
import AddUpdateBenefit from "../pages/benefit_add_update";
import ProjectLink from "../pages/project_link";
import AddUpdateDocument from "../pages/document_add_update";

const employeeRoutes: Array<RouteObject> = [
  {
    path: '/apps-employees-list',
    component: EmployeeList,
    children: [
      {
        path: 'add',
        component: AddUpdateEmployee
      },
      {
        path: 'update/:id',
        component: AddUpdateEmployee
      },
    ]
  },
  {
    path: 'apps-employees-view/:id',
    component: EmployeeAccount,
    children: [
      {
        path: 'update',
        component: AddUpdateEmployee
      },
      {
        path: 'contacts',
        component: AddUpdateContact
      },
      {
        path: 'contacts/:id',
        component: AddUpdateContact
      },
      {
        path: 'addresses',
        component: AddUpdateAddress
      },
      {
        path: 'addresses/:id',
        component: AddUpdateAddress
      },
      {
        path: 'accounts',
        component: AddUpdateAccount
      },
      {
        path: 'accounts/:id',
        component: AddUpdateAccount
      },
      {
        path: 'documents',
        component: AddUpdateDocument
      },
      {
        path: 'documents/:id',
        component: AddUpdateDocument
      },
      {
        path: 'beneficiaries',
        component: AddUpdateBeneficiary
      },
      {
        path: 'beneficiaries/:id',
        component: AddUpdateBeneficiary
      },
      {
        path: 'contracts',
        component: AddUpdateAccount
      },
      {
        path: 'contracts/:id',
        component: AddUpdateAccount
      },
      {
        path: 'work-histories',
        component: AddUpdateWorkHistory
      },
      {
        path: 'work-histories/:id',
        component: AddUpdateWorkHistory
      },
      {
        path: 'educations',
        component: AddUpdateEducation
      },
      {
        path: 'educations/:id',
        component: AddUpdateEducation
      },
      {
        path: 'referees',
        component: AddUpdateReferee
      },
      {
        path: 'referees/:id',
        component: AddUpdateReferee
      },
      {
        path: 'benefits',
        component: AddUpdateBenefit
      },
      {
        path: 'benefits/:id',
        component: AddUpdateBenefit
      },
      {
        path: 'project-link',
        component: ProjectLink
      },
      {
        path: 'project-link/:id',
        component: ProjectLink
      },
    ]
  },
];

export default employeeRoutes;
