import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Contract } from "../types";
import { EmploymentTypes } from "../db";
import { useGetJobTitlesQuery } from "../../job-title/slices/job_title_slice_api";
import { GenericDataList } from "../../../common/types";
import { JobTitle } from "../../job-title/types";

interface AddUpdateProps {
  isOpen: boolean;
  contract?: Contract;
  tenantId: string;
  onClose: () => void;
  onSubmit: (data: Partial<Contract>) => void;
}

/**
 * @function
 * @name AddUpdateContract
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateContract: React.FC<AddUpdateProps> = ({isOpen, contract, tenantId, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Contract>>(contract || {});

  const {data: jobTitles = [], isLoading: isJobTitlesLoading} = useGetJobTitlesQuery({"tenantId": tenantId});
  const [selectedJobTitle, setSelectedJobTitle] = useState<any>(contract?.jobTitleId ?? []);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      contractType: eventData?.contractType ?? '',
      workStation: eventData?.workStation ?? '',
      jobTitleId: eventData?.jobTitleId ?? '',
      recruitmentPlace: eventData?.recruitmentPlace ?? '',
      salaryRenumeration: eventData?.salaryRenumeration ?? '',
      duration: eventData?.duration ?? '',
      startDate: eventData?.startDate ?? '',
      endDate: eventData?.endDate ?? '',
      dateIssued: eventData?.dateIssued ?? '',
    },
    validationSchema: Yup.object({
      contractType: Yup.string().required("Please Enter Title"),
      workStation: Yup.string().required("Please Enter Work Station"),
      jobTitleId: Yup.string().required("Please Enter Job Title"),
      recruitmentPlace: Yup.string().required("Please Enter Recruitment Place"),
      salaryRenumeration: Yup.string().required("Please Enter Salary Renumeration"),
      duration: Yup.string().required("Please Enter Duration"),
      startDate: Yup.string().required("Please Enter Start Date"),
      endDate: Yup.string().optional(),
      dateIssued: Yup.string().required("Please Enter Date Issued"),
    }),
    onSubmit: (
      values: Partial<Contract>,
      {setSubmitting}: FormikHelpers<Partial<Contract>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleChange = (selectedOption: any) => {
    validation.setFieldValue('jobTitleId', selectedOption.value);

    if (selectedOption.value === 'Select JobTitle' || selectedOption.value === 'Hidden') {
      setSelectedJobTitle(jobTitles);
    } else {
      const filteredJobTitles = jobTitles.filter((data: any) => data.id === selectedOption.value);
      setSelectedJobTitle(filteredJobTitles);
    }
  };

  const getJobTitlesSelection = (jobs: JobTitle[]): GenericDataList[] => {
    return jobs.map(job => ({
      label: `${job.positionLevel} - ${job.positionName}`,
      value: job.id,
    }));
  }

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {contract ? "Edit Contract" : "Add Contract"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.values.jobTitleId = selectedJobTitle[0].id;
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="contractContractTypeInput"
                     className="inline-block mb-2 text-base font-medium">
                Contract Type <span className="text-sm text-red-500">*</span>
              </label>
              <Select
                options={EmploymentTypes}
                id="contractContractTypeInput"
                name="contractType"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "contractType",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.contractType && validation.errors.contractType ? (
                <p className="text-red-400">{validation.errors.contractType}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="contractWorkStationInput"
                     className="inline-block mb-2 text-base font-medium">
                Workstation <span className="text-sm text-red-500">*</span>
              </label>
              <input type="text" id="contractWorkStationInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="ABC Ilala"
                     name="workStation"
                     onChange={validation.handleChange}
                     value={validation.values.workStation || ""}
              />
              {validation.touched.workStation && validation.errors.workStation ? (
                <p className="text-red-400">{validation.errors.workStation}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12 mb-3">
              <label htmlFor="contractJobTitleSelect"
                     className="inline-block mb-2 text-base font-medium">
                Job Title <span className="text-sm text-red-500">*</span>
              </label>

              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={getJobTitlesSelection(jobTitles)}
                value={getJobTitlesSelection(jobTitles).find(option => option.value === validation.values.jobTitleId)}
                isSearchable={false}
                onChange={(event: any) => handleChange(event)}
                id="contractJobTitleSelect"
                required={true}
                isLoading={isJobTitlesLoading}
              />
              {validation.touched.jobTitleId && validation.errors.jobTitleId ? (
                <p className="text-red-400">{validation.errors.jobTitleId}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="contractRecruitmentPlaceInput"
                     className="inline-block mb-2 text-base font-medium">
                Recruitment Place <span className="text-sm text-red-500">*</span>
              </label>
              <input type="text" id="contractRecruitmentPlaceInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="HQ"
                     name="recruitmentPlace"
                     onChange={validation.handleChange}
                     value={validation.values.recruitmentPlace || ""}
              />
              {validation.touched.recruitmentPlace && validation.errors.recruitmentPlace ? (
                <p className="text-red-400">{validation.errors.recruitmentPlace}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="contractSalaryRenumerationInput"
                     className="inline-block mb-2 text-base font-medium">
                Salary Renumeration <span className="text-sm text-red-500">*</span>
              </label>
              <input type="text" id="contractSalaryRenumerationInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="TZS 3000000"
                     name="salaryRenumeration"
                     onChange={validation.handleChange}
                     value={validation.values.salaryRenumeration || ""}
              />
              {validation.touched.salaryRenumeration && validation.errors.salaryRenumeration ? (
                <p className="text-red-400">{validation.errors.salaryRenumeration}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="contractDurationInput"
                     className="inline-block mb-2 text-base font-medium">
                Duration <span className="text-sm text-red-500">*</span>
              </label>
              <input type="text" id="contractDurationInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="6 Months"
                     name="duration"
                     onChange={validation.handleChange}
                     value={validation.values.duration || ""}
              />
              {validation.touched.duration && validation.errors.duration ? (
                <p className="text-red-400">{validation.errors.duration}</p>
              ) : null}
            </div>


            <div className="xl:col-span-12">
              <label htmlFor="contractDateIssuedInput" className="inline-block mb-2 text-base font-medium">
                Issued Date <span className="text-sm text-red-500">*</span>
              </label>
              <input type="date"
                     id="contractDateIssuedInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select Start Date"
                     name="dateIssued"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "dateIssued",
                           value: event.target.value,
                         },
                       });
                       validation.values.dateIssued = event.target.value
                     }}
                     value={validation.values.dateIssued || ""}
              />
              {validation.touched.dateIssued && validation.errors.dateIssued ? (
                <p className="text-red-400">{validation.errors.dateIssued}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="contractStartDateInput" className="inline-block mb-2 text-base font-medium">
                Start Date <span className="text-sm text-red-500">*</span>
              </label>
              <input type="date"
                     id="contractStartDateInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select Start Date"
                     name="startDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "startDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.startDate = event.target.value
                     }}
                     value={validation.values.startDate || ""}
              />
              {validation.touched.startDate && validation.errors.startDate ? (
                <p className="text-red-400">{validation.errors.startDate}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="contractEndDateInput" className="inline-block mb-2 text-base font-medium">
                End Date
              </label>
              <input type="date"
                     id="contractEndDateInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select End Date"
                     name="endDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "endDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.endDate = event.target.value
                     }}
                     value={validation.values.endDate || ""}
              />
              {validation.touched.endDate && validation.errors.endDate ? (
                <p className="text-red-400">{validation.errors.endDate}</p>
              ) : null}
            </div>

          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addContractModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {contract ? "Update Contract" : "Add Contract"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateContract;
