import { Building2Icon, FolderOpenDot, LockIcon, MonitorDot, UserRound, Users, Users2Icon } from "lucide-react";

const menuData: any = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    link: '/#',
    icon: <MonitorDot/>,
  },
  {
    label: 'HR MANAGEMENT',
    isTitle: true,
  },
  {
    id: 'employeelist',
    label: 'Employees',
    icon: <Users/>,
    link: '/apps-employees-list',
  },
  // {
  //   id: 'candidateList',
  //   label: 'Candidates Pool',
  //   icon: <BoxesIcon/>,
  //   link: '/apps-hr-holidays',
  // },
  {
    id: 'projects',
    label: 'Projects',
    icon: <FolderOpenDot/>,
    link: '/apps-projects-list',
  },
  //
  //
  // {
  //   label: 'WORKFLOWS',
  //   isTitle: true,
  // },
  // {
  //   id: 'pendingApprovals',
  //   label: 'Pending Approvals',
  //   icon: <LockIcon/>,
  //   link: '/apps-approvals-list',
  // },
  // {
  //   id: 'workflows',
  //   label: 'Workflow Configs',
  //   icon: <LockIcon/>,
  //   link: '/apps-work-flows',
  // },
  //
  // {
  //   label: 'PAYROLL',
  //   isTitle: true,
  // },
  // {
  //   id: 'payrollList',
  //   label: 'Payroll List',
  //   icon: <ReceiptIcon/>,
  //   link: '/apps-hr-payroll-payslip',
  // },
  //
  // {
  //   id: 'createpayslip',
  //   label: 'Create Payslip',
  //   icon: <ReceiptIcon/>,
  //   link: '/apps-hr-payroll-create-payslip',
  // },
  //
  // {
  //   label: 'ATTENDANCE & LEAVE',
  //   isTitle: true,
  // },
  //
  // {
  //   id: 'leaveManagement',
  //   label: 'Leave Management',
  //   icon: <ShirtIcon/>,
  //   link: '/apps-hr-leave',
  // },
  //
  // {
  //   id: 'attendanceManagement',
  //   label: 'Attendance',
  //   icon: <CalendarCog/>,
  //   link: '/apps-hr-attendance',
  // },
  {
    label: 'CORE',
    isTitle: true,
  },

  {
    id: 'users',
    label: 'Users',
    icon: <UserRound/>,
    link: '/apps-users-list',
    parentId: 'configurations',
  },
  {
    id: 'tenants',
    label: 'Tenants',
    icon: <Users2Icon/>,
    link: '/apps-tenants-list',
  },
  {
    id: 'offices',
    label: 'Offices',
    icon: <Building2Icon/>,
    link: '/apps-offices-list',
  },
  {
    id: 'roles',
    label: 'Roles',
    icon: <Users/>,
    link: '/apps-roles-list',
  },
  {
    id: 'permissions',
    label: 'Permissions',
    icon: <LockIcon/>,
    link: '/apps-permissions-list',
  },
  // {
  //   id: 'auditTrails',
  //   label: 'Audit Trails',
  //   icon: <LockIcon/>,
  //   link: '/apps-audit-trails',
  // },
  // {
  //   id: 'notifications',
  //   label: 'Notifications',
  //   icon: <LockIcon/>,
  //   link: '/apps-notifications-list',
  // },

];

export { menuData };
