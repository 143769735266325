import React, { useMemo, useState } from "react";
import { Beneficiary, Employee } from "../types";
import { Eye, Plus, Search } from "lucide-react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useCreateBeneficiaryMutation, useUpdateBeneficiaryMutation } from "../slices/employee_slice_api";
import { toast } from "react-toastify";
import TableContainer from "../../../common/TableContainer";
import AddUpdateBeneficiary from "../pages/beneficiary_add_update";

interface BeneficiaryProps {
  employee: Employee;
  refetch: () => void;
}

const Beneficiaries: React.FC<BeneficiaryProps> = ({employee, refetch}) => {
  const navigate = useNavigate();
  const [createBeneficiary] = useCreateBeneficiaryMutation();
  const [updateBeneficiary] = useUpdateBeneficiaryMutation();

  const [isBeneficiaryModalOpen, setIsBeneficiaryModalOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary | undefined>(undefined)

  const handleBeneficiaryCreate = () => {
    setSelectedBeneficiary(undefined);
    setIsBeneficiaryModalOpen(true);
  };

  const handleBeneficiaryEdit = (beneficiary: Beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setIsBeneficiaryModalOpen(true);
  };

  const handleBeneficiarySubmit = async (data: Partial<Beneficiary>) => {
    try {
      data = {
        ...data,
        employeeId: employee.id
      };

      if (selectedBeneficiary) {
        await updateBeneficiary({...selectedBeneficiary, ...data}).unwrap();
      } else {
        await createBeneficiary(data).unwrap();
      }

      setIsBeneficiaryModalOpen(false);
      toast.success(`Beneficiary successfully ${selectedBeneficiary ? 'updated' : 'created'}`);

      if (refetch) {
        refetch();
      }

      navigate('.')
    } catch (error: any) {
      toast.error(`Failed to ${selectedBeneficiary ? 'update' : 'create'} tenant: ${error!.message}`);

      navigate('.')
    }
  };

  const columns = useMemo(() => [
      {
        header: "First Name",
        accessorKey: "firstName",
        enableColumnFilter: false,
      },
      {
        header: "Sir Name",
        accessorKey: "sirName",
        enableColumnFilter: false,
      },
      {
        header: "Other Name",
        accessorKey: "otherName",
        enableColumnFilter: false,
      },
      {
        header: "ID Number",
        accessorKey: "idNumber",
        enableColumnFilter: false,
      },
      {
        header: "Mobile Number",
        accessorKey: "msisdn",
        enableColumnFilter: false,
      },
      {
        header: "Relationship",
        accessorKey: "relationship",
        enableColumnFilter: false,
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-employees-view/${employee.id}/beneficiaries/${cell.row.original.id}`}
              onClick={() => handleBeneficiaryEdit(cell.row.original)}
            >
              <Eye className="inline-block size-3"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const renderBeneficiaries = () => {
    return employee.beneficiaries && employee.beneficiaries.length > 0 ? (
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(employee.beneficiaries || [])}
        customPageSize={10}
        divclassName="-mx-5 overflow-x-auto"
        tableclassName="w-full whitespace-nowrap"
        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
      />
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Outlet/>
      <div className="card" id="beneficiariesTab">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h5 className="underline grow">Beneficiaries</h5>
            <div className="shrink-0">
              <Link data-modal-target="defaultModal"
                    className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                    to={`/apps-employees-view/${employee.id}/beneficiaries`}
              >
                <button type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleBeneficiaryCreate}
                >
                  <Plus className="inline-block size-2"/>
                  <span className="align-middle">Add Beneficiary</span>
                </button>
              </Link>
            </div>
          </div>

          {renderBeneficiaries()}
        </div>
      </div>

      <AddUpdateBeneficiary
        key={selectedBeneficiary?.id ?? 'newBeneficiary'}
        isOpen={isBeneficiaryModalOpen}
        onClose={() => {
          setIsBeneficiaryModalOpen(false);
          setSelectedBeneficiary(undefined);
          navigate('.');
        }}
        onSubmit={handleBeneficiarySubmit}
        beneficiary={selectedBeneficiary}
      />
    </React.Fragment>
  );
}

export default Beneficiaries;