import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Document } from "../types";
import Select from "react-select";
import { DocumentTypes } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  document?: Document;
  onClose: () => void;
  onSubmit: (data: Partial<Document>) => void;
}

/**
 * @function
 * @name AddUpdateDocument
 * @description add/update
 * @param isOpen
 * @param document
 * @param onClose
 * @param onSubmit
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateDocument: React.FC<AddUpdateProps> = ({isOpen, document, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Document>>(document || {});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const MAX_FILE_SIZE = 1024 * 1024;

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: eventData?.type ?? '',
      name: eventData?.name ?? '',
      file: eventData?.file ?? '',
      status: eventData?.status ?? ''
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Enter Type"),
      name: Yup.string().required("Please Enter Name"),
      file: Yup.string().required("Please Upload File"),
    }),
    onSubmit: async (
      values: Partial<Document>,
      {setSubmitting}: FormikHelpers<Partial<Document>>
    ) => {
      if (!selectedFile) {
        validation.setFieldError('file', 'Please upload a file');
        setSubmitting(false);
        return;
      }

      const base64File = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

      const submissionValues = {
        ...values,
        file: base64File
      };

      onSubmit(submissionValues);
      setSubmitting(false);
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validate file size (1 MB limit)
      if (file.size > MAX_FILE_SIZE) {
        validation.setFieldError('file', 'File must be less than 1 MB');
        return;
      }

      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
      validation.setFieldValue('file', file.name);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
    validation.setFieldValue('file', '');
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {document ? "Edit Document" : "Add Document"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="typeSelect"
                     className="inline-block mb-2 text-base font-medium">
                Type
              </label>
              <Select
                options={DocumentTypes}
                id="typeSelect"
                name="type"
                value={DocumentTypes.find(option => option.value === validation.values.type)}
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "type",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.type && validation.errors.type ? (
                <p className="text-red-400">{validation.errors.type}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="name"
                     className="inline-block mb-2 text-base font-medium">Name</label>
              <input type="text" id="name"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Type"
                     name="name"
                     onChange={validation.handleChange}
                     value={validation.values.name || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="fileInput"
                     className="inline-block mb-2 text-base font-medium">Upload your file</label>
              <span
                className="mr-2 text-sm text-red-300">
                &nbsp; (*Maximum Upload size {`${((MAX_FILE_SIZE / 1024) / 1024)} MB`})
              </span>
              <input
                id="fileInput"
                name="file"
                type="file"
                className="cursor-pointer form-file border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500"
                onChange={handleFileChange}
                accept="*/*"
              />
              {filePreview && (
                <div className="mt-2 flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm">{selectedFile?.name}</span>
                    <span className="text-xs text-gray-500">
                    ({selectedFile ? `${(selectedFile.size / 1024).toFixed(2)} KB` : ''})
                  </span>
                  </div>
                  <button
                    type="button"
                    onClick={removeFile}
                    className="text-red-500 text-sm"
                  >
                    Remove
                  </button>
                </div>
              )}
              {validation.touched.file && validation.errors.file ? (
                <p className="text-red-400 mt-1">{validation.errors.file}</p>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addDocumentModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {document ? "Update Document" : "Add Document"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateDocument;
