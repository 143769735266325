import { User } from "../types";
import { GetUserPayload } from "../types/user";
import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";

export const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getUsers: builder.query<User[], void>({
      query: () => `/provisioner/users`,
    }),

    getUserById: builder.query<User, GetUserPayload>({
      query: ({id}) => `/provisioner/users/${id}`,
    }),

    createUser: builder.mutation<User, Partial<User>>({
      query: (newUser) => ({
        url: `/provisioner/users`,
        method: 'POST',
        body: newUser,
      }),
    }),

    updateUser: builder.mutation<User, Partial<User>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/users/${id}`,
        method: 'PUT',
        body: update,
      }),
    }),

    deleteUser: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/users/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = userApi;
