import React, { useMemo, useState } from "react";
import { Document, Employee } from "../types";
import { ArrowDownToLine, PencilIcon, Plus, Search } from "lucide-react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useCreateDocumentMutation, useUpdateDocumentMutation } from "../slices/employee_slice_api";
import { toast } from "react-toastify";
import TableContainer from "../../../common/TableContainer";
import AddUpdateDocument from "../pages/document_add_update";


interface DocumentProps {
  employee: Employee;
  refetch?: () => void;
}

const Documents: React.FC<DocumentProps> = ({employee, refetch}) => {
  const navigate = useNavigate();
  const [createDocument] = useCreateDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | undefined>(undefined);


  const handleDocumentCreate = () => {
    setSelectedDocument(undefined);
    setIsDocumentModalOpen(true);
  };

  const handleDocumentEdit = (document: Document) => {
    setSelectedDocument(document);
    console.log(selectedDocument);
    setIsDocumentModalOpen(true);
  };

  const handleDocumentSubmit = async (data: Partial<Document>) => {
    try {
      data = {
        ...data,
        employeeId: employee.id
      };

      if (selectedDocument) {
        await updateDocument({...selectedDocument, ...data}).unwrap();
      } else {
        await createDocument(data).unwrap();
      }

      setIsDocumentModalOpen(false);
      toast.success(`Document successfully ${selectedDocument ? 'updated' : 'created'}`);

      if (refetch) {
        refetch();
      }

      navigate('.')
    } catch (error: any) {
      toast.error(`Failed to ${selectedDocument ? 'update' : 'create'} tenant: ${error!.message}`);

      navigate('.')
    }
  };

  const columns = useMemo(() => [
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-employees-view/${employee.id}/documents/${cell.row.original.id}`}
              onClick={() => handleDocumentEdit(cell.row.original)}
            >
              <PencilIcon className="inline-block size-3"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to="#!" onClick={() => {
              const document = cell.row.original;
              console.log("Downloading document", document);
            }}>
              <ArrowDownToLine className="size-3 inline-block ltr:mr-1 rtl:ml-1"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const renderDocuments = () => {
    return employee.documents && employee.documents.length > 0 ? (
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(employee.documents || [])}
        customPageSize={10}
        divclassName="-mx-5 overflow-x-auto"
        tableclassName="w-full whitespace-nowrap"
        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
      />
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );

  }

  return (
    <React.Fragment>
      <Outlet/>
      <div className="card" id="documentsTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Documents (<b className="total-Employs">{employee.documents!.length ?? 0}</b>)
            </h6>
            <div className="shrink-0">
              <Link data-modal-target="defaultModal"
                    className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                    to={`/apps-employees-view/${employee.id}/documents`}
              >
                <button type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleDocumentCreate}
                >
                  <Plus className="inline-block size-2"/>
                  <span className="align-middle">Add Document</span>
                </button>
              </Link>
            </div>
          </div>
          {renderDocuments()}
        </div>
      </div>

      <AddUpdateDocument
        key={selectedDocument?.id ?? 'newDocument'}
        isOpen={isDocumentModalOpen}
        onClose={() => {
          setIsDocumentModalOpen(false);
          setSelectedDocument(undefined);
          navigate('.');
        }}
        document={selectedDocument}
        onSubmit={handleDocumentSubmit}
      />
    </React.Fragment>
  );
}

export default Documents;
