import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { PaymentAccount } from "../types";
import { AccountTypes, PaymentProviders } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  paymentAccount?: PaymentAccount;
  onClose: () => void;
  onSubmit: (data: Partial<PaymentAccount>) => void;
}

/**
 * @function
 * @name AddUpdateAccount
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateAccount: React.FC<AddUpdateProps> = ({isOpen, paymentAccount, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<PaymentAccount>>(paymentAccount || {});

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      provider: eventData?.provider ?? '',
      accountNumber: eventData?.accountNumber ?? '',
      accountType: eventData?.accountType ?? '',
      accountName: eventData?.accountName ?? '',
      status: eventData?.status ?? '',
    },
    validationSchema: Yup.object({
      provider: Yup.string().required("Please Select Provider"),
      accountNumber: Yup.string().required("Please Enter Account Number"),
      accountType: Yup.string().required("Please Select Account Type"),
      accountName: Yup.string().required("Please Enter Account Name"),
      status: Yup.string().required("Please Select Status"),
    }),
    onSubmit: (
      values: Partial<PaymentAccount>,
      {setSubmitting}: FormikHelpers<Partial<PaymentAccount>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {paymentAccount ? "Edit PaymentAccount" : "Add PaymentAccount"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="providerSelect"
                     className="inline-block mb-2 text-base font-medium">
                Provider
              </label>
              <Select
                options={PaymentProviders}
                id="providerSelect"
                name="provider"
                value={PaymentProviders.find(option => option.value.toLowerCase() === validation.values.provider)}
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "provider",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.provider && validation.errors.provider ? (
                <p className="text-red-400">{validation.errors.provider}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="accountTypeSelect"
                     className="inline-block mb-2 text-base font-medium">
                Account Type
              </label>
              <Select
                options={AccountTypes}
                id="accountTypeSelect"
                name="accountType"
                value={AccountTypes.find(option => option.value.toLowerCase() === validation.values.accountType)}
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "accountType",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.accountType && validation.errors.accountType ? (
                <p className="text-red-400">{validation.errors.accountType}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="accountNumberInput"
                     className="inline-block mb-2 text-base font-medium">
                Account Number
              </label>
              <input type="number" id="accountNumberInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Account Number"
                     name="accountNumber"
                     onChange={validation.handleChange}
                     value={validation.values.accountNumber || ""}
              />
              {validation.touched.accountNumber && validation.errors.accountNumber ? (
                <p className="text-red-400">{validation.errors.accountNumber}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="accountNameInput"
                     className="inline-block mb-2 text-base font-medium">Account Name</label>
              <input type="text" id="accountNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Account Name"
                     name="accountName"
                     onChange={validation.handleChange}
                     value={validation.values.accountName || ""}
              />
              {validation.touched.accountName && validation.errors.accountName ? (
                <p className="text-red-400">{validation.errors.accountName}</p>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addPaymentAccountModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {paymentAccount ? "Update PaymentAccount" : "Add PaymentAccount"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateAccount;
