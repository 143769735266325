import React, { useEffect, useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Project } from "../types";
import Select from "react-select";
import { useGetTenantsQuery } from "../../tenant/slices/tenant_api_slice";
import { useGetOfficesQuery } from "../../office/slices/office_slice_api";
import { Tenant } from "../../tenant/types";
import { GenericDataList } from "../../../common/types";
import { Office } from "../../office/types";


interface AddUpdateProps {
  isOpen: boolean;
  project?: Project;
  onClose: () => void;
  onSubmit: (data: Partial<Project>) => void;
}

/**
 * @function
 * @name AddUpdateProject
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateProject: React.FC<AddUpdateProps> = ({isOpen, project, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Project>>(project || {});

  const {data: tenants = [], isLoading: isTenantsLoading} = useGetTenantsQuery();
  const {data: offices = [], isLoading: isOfficesLoading} = useGetOfficesQuery({});

  const [selectedTenant, setSelectedTenant] = useState<any>(project?.tenantId ?? [])
  const [selectedOffice, setSelectedOffice] = useState<any>(project?.officeId ?? []);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: eventData?.id ?? '',
      tenantId: eventData?.tenantId ?? '',
      officeId: eventData?.officeId ?? '',
      name: eventData?.name ?? '',
      description: eventData?.description ?? '',
      startDate: eventData?.startDate ?? '',
      endDate: eventData?.endDate ?? '',
    },
    validationSchema: Yup.object({
      tenantId: Yup.string().required("Please Choose Tenant"),
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
      startDate: Yup.date().required("Please Enter Start Date"),
      endDate: Yup.date()
        .required("Please Enter End Date")
        .min(
          Yup.ref('startDate'),
          "End date must be later than start date"
        ),
    }),
    onSubmit: (values: Partial<Project>, {setSubmitting}: FormikHelpers<Partial<Project>>) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleTenantChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setSelectedTenant(tenants);
    } else {
      const filteredTenants = tenants.filter((data: any) => data.id === selectedOption.value);
      setSelectedTenant(filteredTenants);
    }
  };

  const handleOfficeChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Office' || selectedOption.value === 'Hidden') {
      setSelectedOffice(offices);
    } else {
      const filteredOffices = offices.filter((data: any) => data.id === selectedOption.value);
      setSelectedOffice(filteredOffices);
    }
  };

  const getTenantsSelection = (tenants: Tenant[]): GenericDataList[] => {
    return tenants.map(tenant => ({
      label: `${tenant.code} - ${tenant.name}`,
      value: tenant.id,
    }));
  }

  const getOfficesSelection = (officesList: Office[]): GenericDataList[] => {
    return officesList.map(office => ({
      label: `${office.name}`,
      value: office.id,
    }));
  }

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{project ? "Edit Project" : "Add Project"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
            validation.values.tenantId = selectedTenant[0].id;
            validation.values.officeId = selectedOffice[0]?.id;
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="tenantSelect"
                   className="inline-block mb-2 text-base font-medium">
              Tenant
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={getTenantsSelection(tenants)}
              isSearchable={false}
              onChange={(event: any) => handleTenantChange(event)}
              id="tenantSelect"
              required={true}
              isLoading={isTenantsLoading}
            />
            {validation.touched.tenantId && validation.errors.tenantId ? (
              <p className="text-red-400">{validation.errors.tenantId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="officeSelect"
                   className="inline-block mb-2 text-base font-medium">
              Office
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={getOfficesSelection(offices)}
              isSearchable={false}
              onChange={(event: any) => handleOfficeChange(event)}
              id="officeSelect"
              required={false}
              isLoading={isOfficesLoading}
            />
            {validation.touched.officeId && validation.errors.officeId ? (
              <p className="text-red-400">{validation.errors.officeId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="userNameInput"
                   className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input type="text" id="userNameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter name"
                   name="name"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
            />
            {validation.touched.name && validation.errors.name ? (
              <p className="text-red-400">{validation.errors.name}</p>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="descriptionInput"
                   className="inline-block mb-2 text-base font-medium">
              Description
            </label>
            <input type="text" id="descriptionInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter description"
                   name="description"
                   onChange={validation.handleChange}
                   value={validation.values.description || ""}
                   required={false}
            />
            {validation.touched.description && validation.errors.description ? (
              <p className="text-red-400">{validation.errors.description}</p>
            ) : null}
          </div>

          <div className="mb-3">
            <label
              htmlFor="startDateInput"
              className="inline-block mb-2 text-base font-medium"
            >
              Start Date
            </label>
            <input
              type="date"
              id="startDateInput"
              name="startDate"
              className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 dark:bg-zink-700"
              onChange={validation.handleChange}
              value={validation.values.startDate || ""}
            />
            {validation.touched.startDate && validation.errors.startDate ? (
              <p className="text-red-400">{validation.errors.startDate}</p>
            ) : null}
          </div>

          <div className="mb-3">
            <label
              htmlFor="endDateInput"
              className="inline-block mb-2 text-base font-medium"
            >
              End Date
            </label>
            <input
              type="date"
              id="endDateInput"
              name="endDate"
              className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 dark:bg-zink-700"
              onChange={validation.handleChange}
              value={validation.values.endDate || ""}
              min={validation.values.startDate || undefined}
            />
            {validation.touched.endDate && validation.errors.endDate ? (
              <p className="text-red-400">{validation.errors.endDate}</p>
            ) : null}
          </div>

          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" data-modal-close="addDocuments"
                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit"
                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {project ? "Update Project" : "Add Project"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateProject;
