import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import AuthProtected from './AuthProtected';
import Layout from "../features/layout";
import NonAuthLayout from "../features/layout/NonLayout";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  children?: RouteObject[];
  isPublic?: boolean;
}

const RouteIndex: React.FC = () => {
  const wrapComponent = (
    route: RouteObject,
    RouteComponent: React.ComponentType<any>,
    isParent: boolean
  ): JSX.Element => {
    if (route.isPublic) {
      return (
        <NonAuthLayout>
          <RouteComponent/>
        </NonAuthLayout>
      );
    }

    if (isParent && !route.isPublic) {
      return (
        <AuthProtected>
          <Layout>
            <RouteComponent/>
          </Layout>
        </AuthProtected>
      );
    }

    return <RouteComponent/>;
  };

  const renderRoutes = (
    routes: RouteObject[],
    isParent: boolean = true
  ): React.ReactNode => {
    return routes.map((route: RouteObject, idx: number) => {
      const RouteComponent = route.component;
      const element = wrapComponent(route, RouteComponent, isParent);

      return (
        <Route
          key={`${route.path}_${idx}`}
          path={route.path}
          element={element}
        >
          {route.children && renderRoutes(route.children, false)}
        </Route>
      );
    });
  };

  const allRoutes: RouteObject[] = [
    ...authProtectedRoutes,
    ...publicRoutes.map(route => ({...route, isPublic: true}))
  ];

  return <Routes>{renderRoutes(allRoutes)}</Routes>;
};

export default RouteIndex;