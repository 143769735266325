import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { GetJobCategoryPayload, JobCategory } from "../types";

export const jobCategoryApi = createApi({
  reducerPath: 'jobCategoryApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getJobCategories: builder.query<JobCategory[], void>({
      query: () => `/personnel/job-categories`,
    }),

    getJobCategoryById: builder.query<JobCategory, GetJobCategoryPayload>({
      query: ({id}) => `/personnel/job-categories/${id}`,
    }),

    createJobCategory: builder.mutation<JobCategory, Partial<JobCategory>>({
      query: (newJobCategory) => ({
        url: `/personnel/job-categories`,
        method: 'POST',
        body: newJobCategory,
      }),
    }),

    updateJobCategory: builder.mutation<JobCategory, Partial<JobCategory>>({
      query: ({id, ...update}) => ({
        url: `/personnel/job-categories/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    deleteJobCategory: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/personnel/job-categories/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetJobCategoriesQuery,
  useGetJobCategoryByIdQuery,
  useCreateJobCategoryMutation,
  useUpdateJobCategoryMutation,
  useDeleteJobCategoryMutation
} = jobCategoryApi;
