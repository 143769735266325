import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Beneficiary } from "../types";
import { Genders, RelationshipTypes, Titles } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  beneficiary?: Beneficiary;
  onClose: () => void;
  onSubmit: (data: Partial<Beneficiary>) => void;
}

/**
 * @function
 * @name AddUpdateBeneficiary
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateBeneficiary: React.FC<AddUpdateProps> = ({isOpen, beneficiary, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Beneficiary>>(beneficiary || {});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const MAX_FILE_SIZE = 1024 * 1024;

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: eventData?.title ?? '',
      firstName: eventData?.firstName ?? '',
      otherNames: eventData?.otherNames ?? '',
      sirName: eventData?.sirName ?? '',
      gender: eventData?.gender ?? '',
      idNumber: eventData?.idNumber ?? '',
      birthDate: eventData?.birthDate ?? '',
      msisdn: eventData?.msisdn ?? '',
      photo: eventData?.photo ?? '',
      remarks: eventData?.remarks ?? '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      firstName: Yup.string().required("Please Enter First Name"),
      sirName: Yup.string().required("Please Enter Sir Name"),
      otherNames: Yup.string().optional(),
      gender: Yup.string().required("Please Enter Gender"),
      relationship: Yup.string().required("Please Select Relationship"),
      idNumber: Yup.string().optional(),
      birthDate: Yup.string().optional(),
      msisdn: Yup.string().optional(),
      photo: Yup.string().optional(),
      remarks: Yup.string().optional(),
    }),
    onSubmit: async (
      values: Partial<Beneficiary>,
      {setSubmitting}: FormikHelpers<Partial<Beneficiary>>
    ) => {
      console.log("Submitting...", values);

      if (!selectedFile) {
        validation.setFieldError('photo', 'Please upload a photo');
        setSubmitting(false);
        return;
      }

      const base64Photo = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

      const submissionValues = {
        ...values,
        photo: base64Photo
      };

      onSubmit(submissionValues);
      setSubmitting(false);
    },
  });


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const photo = e.target.files?.[0];
    if (photo) {
      // Validate photo size (1 MB limit)
      if (photo.size > MAX_FILE_SIZE) {
        validation.setFieldError('photo', 'Photo must be less than 1 MB');
        return;
      }

      setSelectedFile(photo);
      setFilePreview(URL.createObjectURL(photo));
      validation.setFieldValue('photo', photo.name);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
    validation.setFieldValue('photo', '');
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {beneficiary ? "Edit Beneficiary" : "Add Beneficiary"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="titleSelect"
                     className="inline-block mb-2 text-base font-medium">
                Title
              </label>
              <Select
                options={Titles}
                id="titleSelect"
                name="title"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "title",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-400">{validation.errors.title}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="beneficiaryFirstNameInput"
                     className="inline-block mb-2 text-base font-medium">First Name</label>
              <input type="text" id="beneficiaryFirstNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="First name"
                     name="firstName"
                     onChange={validation.handleChange}
                     value={validation.values.firstName || ""}
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <p className="text-red-400">{validation.errors.firstName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="beneficiarySirNameInput"
                     className="inline-block mb-2 text-base font-medium">Sir Name</label>
              <input type="text" id="beneficiarySirNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Sir name"
                     name="sirName"
                     onChange={validation.handleChange}
                     value={validation.values.sirName || ""}
              />
              {validation.touched.sirName && validation.errors.sirName ? (
                <p className="text-red-400">{validation.errors.sirName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="beneficiaryOtherNameInput"
                     className="inline-block mb-2 text-base font-medium">Other Names</label>
              <input type="text" id="beneficiaryOtherNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Other name"
                     name="otherNames"
                     onChange={validation.handleChange}
                     value={validation.values.otherNames || ""}
              />
              {validation.touched.otherNames && validation.errors.otherNames ? (
                <p className="text-red-400">{validation.errors.otherNames}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="birthDate" className="inline-block mb-2 text-base font-medium">Birth Date</label>
              <input type="date"
                     id="birthDate"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select Birth Date"
                     name="birthDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "birthDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.birthDate = event.target.value
                     }}
                     value={validation.values.birthDate || ""}
              />
              {validation.touched.birthDate && validation.errors.birthDate ? (
                <p className="text-red-400">{validation.errors.birthDate}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="genderSelect"
                     className="inline-block mb-2 text-base font-medium">Gender</label>
              <Select
                options={Genders}
                id="genderSelect"
                name="gender"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "gender",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.gender && validation.errors.gender ? (
                <p className="text-red-400">{validation.errors.gender}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="relationshipSelect"
                     className="inline-block mb-2 text-base font-medium">
                Relationship
              </label>
              <Select
                options={RelationshipTypes}
                id="relationshipSelect"
                name="relationship"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "relationship",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.relationship && validation.errors.relationship ? (
                <p className="text-red-400">{validation.errors.relationship}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="msisdnInput"
                     className="inline-block mb-2 text-base font-medium">Mobile Number</label>
              <input type="phone" id="msisdnInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="0714567890"
                     name="msisdn"
                     onChange={validation.handleChange}
                     value={validation.values.msisdn || ""}
              />
              {validation.touched.msisdn && validation.errors.msisdn ? (
                <p className="text-red-400">{validation.errors.msisdn}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="beneficiaryIdNumberInput"
                     className="inline-block mb-2 text-base font-medium">
                ID Number
              </label>
              <input type="text" id="beneficiaryIdNumberInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="ID Number"
                     name="idNumber"
                     onChange={validation.handleChange}
                     value={validation.values.idNumber || ""}
              />
              {validation.touched.idNumber && validation.errors.idNumber ? (
                <p className="text-red-400">{validation.errors.idNumber}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12">
              <label htmlFor="beneficiaryPhotoInput"
                     className="inline-block mb-2 text-base font-medium">Upload your file</label>
              <input
                id="beneficiaryPhotoInput"
                name="photo"
                type="file"
                className="cursor-pointer form-file border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500"
                onChange={handleFileChange}
                accept="*/*"
              />
              {filePreview && (
                <div className="mt-2 flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm">{selectedFile?.name}</span>
                    <span className="text-xs text-gray-500">
                    ({selectedFile ? `${(selectedFile.size / 1024).toFixed(2)} KB` : ''})
                  </span>
                  </div>
                  <button
                    type="button"
                    onClick={removeFile}
                    className="text-red-500 text-sm"
                  >
                    Remove
                  </button>
                </div>
              )}
              {validation.touched.photo && validation.errors.photo ? (
                <p className="text-red-400 mt-1">{validation.errors.photo}</p>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addBeneficiaryModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {beneficiary ? "Update Beneficiary" : "Add Beneficiary"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateBeneficiary;
