import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { GetPermissionPayload } from "../types/permission";
import { Permission } from "../types";

export const permissionApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getPermissions: builder.query<Permission[], { search?: { [key: string]: string } }>({
      query: (params) => ({
        url: `/provisioner/permissions`,
        params: params?.search || {}
      }),
    }),

    getPermissionById: builder.query<Permission, GetPermissionPayload>({
      query: ({id}) => `/provisioner/permissions/${id}`,
    }),

    createPermission: builder.mutation<Permission, Partial<Permission>>({
      query: (newPermission) => ({
        url: `/provisioner/permissions`,
        method: 'POST',
        body: newPermission,
      }),
    }),

    updatePermission: builder.mutation<Permission, Partial<Permission>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/permissions/${id}`,
        method: 'PUT',
        body: update,
      }),
    }),

    deletePermission: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/permissions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetPermissionByIdQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation
} = permissionApi;
