import React, { useEffect, useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Permission } from "../types";
import { Tenant } from "../../tenant/types";
import Select from "react-select";
import { PermissionStatuses } from "../db";

interface AddUpdateProps {
  isOpen: boolean;
  permission?: Permission;
  onClose: () => void;
  onSubmit: (data: Partial<Tenant>) => void;
}

/**
 * @function
 * @name AddUpdatePermission
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdatePermission: React.FC<AddUpdateProps> = ({isOpen, permission, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Permission>>(permission || {});

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setEventData({...eventData, [name]: value});
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: eventData?.name ?? '',
      description: eventData?.description ?? '',
      moduleName: eventData?.moduleName ?? '',
      active: eventData?.active ?? false,
      id: eventData?.id ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
      moduleName: Yup.string().required("Please Enter Module Name"),
      active: Yup.string().required("Please Enter Status"),
      id: Yup.string().notRequired(),
    }),
    onSubmit: (values: Partial<Permission>, {setSubmitting}: FormikHelpers<Partial<Permission>>) => {
      onSubmit(values);
      setSubmitting(false);
    }
  });

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{permission ? "Edit Permission" : "Add Permission"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="moduleName" className="inline-block mb-2 text-base font-medium">
              Module Name
            </label>
            <input type="text" id="moduleName"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   name="moduleName"
                   value={validation.values.moduleName || ""}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="nameInput"
                   className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input type="text"
                   id="nameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter name"
                   name="name"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
            />
            {validation.touched.name && validation.errors.name ? (
              <p className="text-red-400">{validation.errors.name}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="descriptionInput"
                   className="inline-block mb-2 text-base font-medium">
              Description
            </label>
            <input type="text"
                   id="descriptionInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter description"
                   name="description"
                   onChange={handleChange}
                   defaultValue={validation.values.name || ""}
            />
            {validation.touched.description && validation.errors.description ? (
              <p className="text-red-400">{validation.errors.description}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="statusSelect"
                   className="inline-block mb-2 text-base font-medium">
              Status
            </label>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={PermissionStatuses}
              name="active"
              isSearchable={false}
              onChange={(selectedOption: any) => {
                validation.handleChange({
                  target: {
                    name: "active",
                    value: selectedOption.value,
                  },
                });
              }}
              id="statusSelect"
              required={true}
            />
            {validation.touched.active && validation.errors.active ? (
              <p className="text-red-400">{validation.errors.active}</p>
            ) : null}
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" data-modal-close="defaultModal"
                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit"
                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {permission ? "Update Permission" : "Add Permission"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdatePermission;
