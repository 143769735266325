import React, { useEffect, useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Office } from "../types";
import Select from "react-select";
import { useGetTenantsQuery } from "../../tenant/slices/tenant_api_slice";
import { Tenant } from "../../tenant/types";
import { GenericDataList } from "../../../common/types";

interface AddUpdateProps {
  isOpen: boolean;
  office?: Office;
  onClose: () => void;
  onSubmit: (data: Partial<Office>) => void;
}


/**
 * @function
 * @name AddUpdateOffice
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateOffice: React.FC<AddUpdateProps> = ({isOpen, office, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Office>>(office || {});
  const {data: tenants = [], isLoading: isTenantsLoading} = useGetTenantsQuery();
  const [selectedTenant, setSelectedTenant] = useState<any>(office?.tenantId ?? [])

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: eventData?.id ?? '',
      tenantId: eventData?.tenantId ?? '',
      name: eventData?.name ?? '',
      address: eventData?.address ?? '',
    },
    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      tenantId: Yup.string().required("Please Choose Tenant"),
      name: Yup.string().required("Please Enter Name"),
      address: Yup.string().notRequired(),
    }),
    onSubmit: (
      values: Partial<Office>,
      {setSubmitting}: FormikHelpers<Partial<Office>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const getTenantsSelection = (tenants: Tenant[]): GenericDataList[] => {
    return tenants.map(tenant => ({
      label: `${tenant.code} - ${tenant.name}`,
      value: tenant.id,
    }));
  }

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setSelectedTenant(tenants);
    } else {
      const filteredTenants = tenants.filter((data: any) => data.id === selectedOption.value);
      setSelectedTenant(filteredTenants);
    }
  };

  return (
    <Modal show={isOpen} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{office ? "Edit Office" : "Add Office"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
            validation.values.tenantId = selectedTenant[0].id;
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="tenantSelect"
                   className="inline-block mb-2 text-base font-medium">
              Tenant
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={getTenantsSelection(tenants)}
              isSearchable={false}
              onChange={(event: any) => handleChange(event)}
              id="choices-single-default"
              required={true}
              isLoading={isTenantsLoading}
            />
            {validation.touched.tenantId && validation.errors.tenantId ? (
              <p className="text-red-400">{validation.errors.tenantId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="NameInput"
                   className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input type="text" id="NameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter name"
                   name="name"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
            />
            {validation.touched.name && validation.errors.name ? (
              <p className="text-red-400">{validation.errors.name}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="addressInput"
                   className="inline-block mb-2 text-base font-medium">
              Address
            </label>
            <input type="text" id="addressInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter address"
                   name="address"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
                   required={false}
            />
            {validation.touched.address && validation.errors.address ? (
              <p className="text-red-400">{validation.errors.address}</p>
            ) : null}
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" data-modal-close="addDocuments"
                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit"
                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {office ? "Update Office" : "Add Office"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateOffice;
