import HRDashboard from "pages/Dashboard";


import React from "react";
import tenantRoutes from "../features/tenant/routes";
import authRoutes from "../features/auth/routes";
import permissionRoutes from "../features/permission/routes";
import officeRoutes from "../features/office/routes";
import projectRoutes from "../features/project/routes";
import roleRoutes from "../features/role/routes";
import employeeRoutes from "../features/employee/routes";
import userRoutes from "../features/user/routes";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  {path: "/", component: HRDashboard},

  // HR Management
  // {path: "/apps-hr-holidays", component: Holidays},
  // {path: "/apps-hr-leave-employee", component: LeaveManageEmployee},
  // {path: "/apps-hr-create-leave-employee", component: AddLeaveEmployee},
  // {path: "/apps-hr-leave", component: LeaveManageHR},
  // {path: "/apps-hr-create-leave", component: AddLeaveHR},
  // {path: "/apps-hr-attendance", component: AttendanceHR},
  // {path: "/apps-hr-attendance-main", component: MainAttendance},
  // {path: "/apps-hr-payroll-employee-salary", component: EmployeeSalary},
  // {path: "/apps-hr-payroll-payslip", component: Payslip},
  // {path: "/apps-hr-payroll-create-payslip", component: CreatePayslip},

  ...tenantRoutes,
  ...permissionRoutes,
  ...officeRoutes,
  ...projectRoutes,
  ...roleRoutes,
  ...employeeRoutes,
  ...userRoutes

  // pages
  // {path: "/apps-users-settings", component: Settings},

  // profile
  // {path: "/user-profile", component: UserProfile},
];

const publicRoutes = [
  ...authRoutes
]

export { authProtectedRoutes, publicRoutes };
