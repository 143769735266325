import React, { useMemo, useState } from "react";
import { Eye, Plus, Search } from "lucide-react";
import { Contract, Employee } from "../types";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useCreateContractMutation, useUpdateContractMutation } from "../slices/employee_slice_api";
import { toast } from "react-toastify";
import TableContainer from "../../../common/TableContainer";
import AddUpdateContract from "../pages/contract_add_update";

interface ContractProps {
  employee: Employee;
  refetch?: () => void;
}

const Contracts: React.FC<ContractProps> = ({employee, refetch}) => {
  const navigate = useNavigate();
  const [createContract] = useCreateContractMutation();
  const [updateContract] = useUpdateContractMutation();

  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined);

  const handleContractCreate = () => {
    setSelectedContract(undefined);
    setIsContractModalOpen(true);
  };

  const handleContractEdit = (education: Contract) => {
    setSelectedContract(education);
    setIsContractModalOpen(true);
  };

  const handleContractSubmit = async (data: Partial<Contract>) => {
    try {
      data = {
        ...data,
        employeeId: employee.id
      };

      if (selectedContract) {
        await updateContract({...selectedContract, ...data}).unwrap();
      } else {
        await createContract(data).unwrap();
      }

      setIsContractModalOpen(false);
      toast.success(`Contract successfully ${selectedContract ? 'updated' : 'created'}`);

      if (refetch) {
        refetch();
      }

      navigate('.')
    } catch (error: any) {
      toast.error(`Failed to ${selectedContract ? 'update' : 'create'} tenant: ${error!.message}`);

      navigate('.')
    }
  };


  const columns = useMemo(() => [
      {
        header: "Institution",
        accessorKey: "institution",
        enableColumnFilter: false,
      },
      {
        header: "Qualification",
        accessorKey: "qualification",
        enableColumnFilter: false,
      },
      {
        header: "Award",
        accessorKey: "award",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false
      },
      {
        header: "Start Date",
        accessorKey: "startDate",
        enableColumnFilter: false,
      },
      {
        header: "End Date",
        accessorKey: "endDate",
        enableColumnFilter: false,
      },
      {
        header: "Issue Date",
        accessorKey: "issueDate",
        enableColumnFilter: false,
      },
      {
        header: "Reference",
        accessorKey: "reference",
        enableColumnFilter: false,
      },
      {
        header: "Document Reference",
        accessorKey: "documentReference",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-employees-view/${employee.id}/contracts/${cell.row.original.id}`}
              onClick={() => handleContractEdit(cell.row.original)}
            >
              <Eye className="inline-block size-3"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const renderContracts = () => {
    return employee.contracts && employee.contracts.length > 0 ? (
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(employee.contracts || [])}
        customPageSize={10}
        divclassName="-mx-5 overflow-x-auto"
        tableclassName="w-full whitespace-nowrap"
        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
      />
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );

  }


  return (
    <React.Fragment>
      <Outlet/>
      <div className="card" id="educationTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Contracts (<b className="total-Employs">{employee.contracts!.length ?? 0}</b>)
            </h6>
            <div className="shrink-0">
              <Link data-modal-target="defaultModal"
                    className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                    to={`/apps-employees-view/${employee.id}/contracts`}
              >
                <button type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleContractCreate}
                >
                  <Plus className="inline-block size-2"/>
                  <span className="align-middle">Add Contract</span>
                </button>
              </Link>
            </div>
          </div>
          {renderContracts()}
        </div>
      </div>

      <AddUpdateContract
        key={selectedContract?.id ?? 'newEducation'}
        isOpen={isContractModalOpen}
        onClose={() => {
          setIsContractModalOpen(false);
          setSelectedContract(undefined);
          navigate('.');
        }}
        contract={selectedContract}
        tenantId={employee.tenantId!}
        onSubmit={handleContractSubmit}
      />
    </React.Fragment>
  );
}

export default Contracts;