export const VIEW_EMPLOYEE_LIST = "";
export const VIEW_EMPLOYEE = "";
export const UPDATE_EMPLOYEE_LIST = "";
export const DELETE_EMPLOYEE_LIST = "";
export const APPROVE_EMPLOYEE_LIST = "";


export const Titles = [
  {
    value: "mr",
    label: "Mr"
  },
  {
    value: "mrs",
    label: "Mrs"
  },
  {
    value: "ms",
    label: "Miss"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const MaritalStatuses = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "married",
    label: "Married",
  },
  {
    value: "divorced",
    label: "Divorced",
  },
  {
    value: "widowed",
    label: "Widowed",
  },
];

export const Genders = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  }
];

export const EmploymentStatuses = [
  {
    value: '',
    label: 'Select Status'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'draft',
    label: 'Draft'
  },
];

export const ContactTypes = [
  {
    value: "email",
    label: "Email",
  },
  {
    value: "mobile",
    label: "Mobile",
  },
  {
    value: "socialMedia",
    label: "Social Media",
  }
];

export const GeneralStatuses = [
  {
    value: '',
    label: 'Select Status'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'draft',
    label: 'Draft'
  },
];

export const PaymentProviders = [
  {
    value: '',
    label: 'Select Provider'
  },
  {
    value: 'akiba',
    label: 'Akiba'
  },
  {
    value: 'crdb',
    label: 'CRDB'
  },
  {
    value: 'nmb',
    label: 'NMB'
  },
  {
    value: 'nbc',
    label: 'NBC'
  },
  {
    value: 'm-pesa',
    label: 'M-PESA'
  },
  {
    value: 'yas',
    label: 'YAS'
  },
  {
    value: 'halopesa',
    label: 'HaloPesa'
  },
];

export const AccountTypes = [
  {
    value: '',
    label: 'Select Account'
  },
  {
    value: 'bank',
    label: 'Bank'
  },
  {
    value: 'wallet',
    label: 'Wallet'
  }
];

export const AddressTypes = [
  {
    value: '',
    label: 'Select Address'
  },
  {
    value: 'physical',
    label: 'Physical'
  },
  {
    value: 'domicile',
    label: 'Domicile'
  },
];

export const DocumentTypes = [
  {
    value: 'cv',
    label: 'CV'
  },
  {
    value: 'offerletter',
    label: 'Offer Letter'
  },
  {
    value: 'contract',
    label: 'Contract'
  },
  {
    value: 'certificate',
    label: 'Certificate'
  },
  {
    value: 'salary',
    label: 'Salary Document'
  },
  {
    value: 'tin',
    label: 'Tax Identification Number'
  },
  {
    value: 'workPermit',
    label: 'Work Permit'
  },
  {
    value: 'nid',
    label: 'National ID'
  },
  {
    value: 'passport',
    label: 'Passport'
  }
];

export const RelationshipTypes = [
  {
    value: '',
    label: 'Select Relationship'
  },
  {
    value: 'parent',
    label: 'PARENT'
  },
  {
    value: 'son',
    label: 'SON'
  },
  {
    value: 'daughter',
    label: 'DAUGHTER'
  },
  {
    value: 'husband',
    label: 'HUSBAND'
  },
  {
    value: 'wife',
    label: 'WIFE'
  },
  {
    value: 'grandfather',
    label: 'GRANDFATHER'
  },
  {
    value: 'grandmother',
    label: 'GRANDMOTHER'
  },
  {
    value: 'cousin',
    label: 'COUSIN'
  },
  {
    value: 'uncle',
    label: 'UNCLE'
  },
  {
    value: 'sister',
    label: 'SISTER'
  },
  {
    value: 'brother',
    label: 'BROTHER'
  },
  {
    value: 'others',
    label: 'OTHERS'
  },
];

export const EmploymentTypes = [
  {
    value: '',
    label: 'Select Employment Type'
  },
  {
    value: 'fulltime',
    label: 'Full Time'
  },
  {
    value: 'consultancy',
    label: 'Consultancy'
  },
  {
    value: 'specificTask',
    label: 'Specific Task'
  },
  {
    value: 'projectBased',
    label: 'Project Based'
  },
  {
    value: 'fixedPeriod',
    label: 'Fixed Period'
  },
];

export const BenefitGroups = [
  {
    value: '',
    label: 'Select Benefit Group'
  },
  {
    value: 'health',
    label: 'Health'
  },
  {
    value: 'retirement',
    label: 'Retirement'
  },
  {
    value: 'salary',
    label: 'Salary'
  },
  {
    value: 'fuel',
    label: 'Fuel'
  },
];

