import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { GetJobTitlePayload, JobTitle } from "../types";

export const jobTitleApi = createApi({
  reducerPath: 'jobTitleApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getJobTitles: builder.query<JobTitle[], { [key: string]: string }>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        Object.entries(params || {}).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            searchParams.append(key, value);
          }
        });

        return {
          url: `/personnel/job-titles${searchParams.toString() ? `?${searchParams.toString()}` : ''}`
        };
      },
    }),

    getJobTitleById: builder.query<JobTitle, GetJobTitlePayload>({
      query: ({id}) => `/personnel/job-titles/${id}`,
    }),

    createJobTitle: builder.mutation<JobTitle, Partial<JobTitle>>({
      query: (newJobTitle) => ({
        url: `/personnel/job-titles`,
        method: 'POST',
        body: newJobTitle,
      }),
    }),

    updateJobTitle: builder.mutation<JobTitle, Partial<JobTitle>>({
      query: ({id, ...update}) => ({
        url: `/personnel/job-titles/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    deleteJobTitle: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/personnel/job-titles/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetJobTitlesQuery,
  useGetJobTitleByIdQuery,
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
  useDeleteJobTitleMutation
} = jobTitleApi;
