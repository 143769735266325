// Define response types for the transformed data
import { AuthData, AuthPayload, LogoutPayload, User } from "../types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { LogoutResponse } from "../../../pages/types";

interface TransformedAuthResponse {
  user: User;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: rtkBaseQuery,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    login: builder.mutation<TransformedAuthResponse, AuthPayload>({
      query: (credentials) => ({
        url: '/provisioner/auth/login',
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (response: AuthData) => {
        localStorage.setItem('authUser', JSON.stringify(response))
        return {user: response!.user};
      },
      // Invalidate the Auth tag on successful login
      invalidatesTags: ['Auth'],
    }),

    logout: builder.mutation<LogoutResponse, LogoutPayload>({
      query: (payload) => ({
        url: '/provisioner/auth/logout',
        method: 'POST',
        body: payload
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const response = await queryFulfilled;
          console.log('Logout response')
          // Remove auth user from localStorage on successful logout
          if (response.data.respCode === 600) {
            localStorage.removeItem('authUser');
          }
        } catch (error) {
          // Add handler for this
          console.log(error)
        }
      },
      // Invalidate the Auth tag on successful logout
      invalidatesTags: ['Auth'],
    }),
  }),
});

// Export hooks for usage in components
export const {
  useLoginMutation,
  useLogoutMutation,
} = authApi;

// Initial state selector - can be used if you need to access auth state
export const getLoggedInUser = () => {
  const authUser = localStorage.getItem('authUser');
  return authUser ? JSON.parse(authUser).user : null;
};
