import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import {
  Address,
  Beneficiary,
  Benefit,
  ContactInfo,
  Contract,
  Document,
  Education,
  Employee,
  GetEmployeePayload,
  Membership,
  PaymentAccount,
  Referee,
  WorkHistory
} from "../types";

export const employeeApi = createApi({
  reducerPath: 'employeesApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getEmployees: builder.query<Employee[], void>({
      query: () => `/personnel/employee`,
    }),

    getEmployeeById: builder.query<Employee, GetEmployeePayload>({
      query: ({id}) => `/personnel/employee/${id}`,
    }),

    createEmployee: builder.mutation<Employee, Partial<Employee>>({
      query: (newEmployee) => ({
        url: `/personnel/employee`,
        method: 'POST',
        body: newEmployee,
      }),
    }),

    updateEmployee: builder.mutation<Employee, Partial<Employee>>({
      query: ({id, ...update}) => ({
        url: `/personnel/employee/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    deleteEmployee: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/personnel/employee/${id}`,
        method: 'DELETE',
      }),
    }),

    createContact: builder.mutation<ContactInfo, Partial<ContactInfo>>({
      query: (contactInfo) => ({
        url: `/personnel/contact`,
        method: 'POST',
        body: contactInfo,
      }),
    }),

    updateContact: builder.mutation<ContactInfo, Partial<ContactInfo>>({
      query: ({id, ...update}) => ({
        url: `/personnel/contact/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createAddress: builder.mutation<Address, Partial<Address>>({
      query: (newAddress) => ({
        url: `/personnel/address`,
        method: 'POST',
        body: newAddress,
      }),
    }),

    updateAddress: builder.mutation<Address, Partial<Address>>({
      query: ({id, ...update}) => ({
        url: `/personnel/address/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createPaymentAccount: builder.mutation<PaymentAccount, Partial<PaymentAccount>>({
      query: (newPaymentAccount) => ({
        url: `/personnel/payment-account`,
        method: 'POST',
        body: newPaymentAccount,
      }),
    }),

    updatePaymentAccount: builder.mutation<PaymentAccount, Partial<PaymentAccount>>({
      query: ({id, ...update}) => ({
        url: `/personnel/payment-account/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createBeneficiary: builder.mutation<Beneficiary, Partial<Beneficiary>>({
      query: (newBeneficiary) => ({
        url: `/personnel/beneficiary`,
        method: 'POST',
        body: newBeneficiary,
      }),
    }),

    updateBeneficiary: builder.mutation<Beneficiary, Partial<Beneficiary>>({
      query: ({id, ...update}) => ({
        url: `/personnel/beneficiary/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createContract: builder.mutation<Contract, Partial<Contract>>({
      query: (newContract) => ({
        url: `/personnel/contract`,
        method: 'POST',
        body: newContract,
      }),
    }),

    updateContract: builder.mutation<Contract, Partial<Contract>>({
      query: ({id, ...update}) => ({
        url: `/personnel/contract/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createWorkHistory: builder.mutation<WorkHistory, Partial<WorkHistory>>({
      query: (newWorkHistory) => ({
        url: `/personnel/employment`,
        method: 'POST',
        body: newWorkHistory,
      }),
    }),

    updateWorkHistory: builder.mutation<WorkHistory, Partial<WorkHistory>>({
      query: ({id, ...update}) => ({
        url: `/personnel/employment/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createEducation: builder.mutation<Education, Partial<Education>>({
      query: (newEducation) => ({
        url: `/personnel/education`,
        method: 'POST',
        body: newEducation,
      }),
    }),

    updateEducation: builder.mutation<Education, Partial<Education>>({
      query: ({id, ...update}) => ({
        url: `/personnel/education/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createReferee: builder.mutation<Referee, Partial<Referee>>({
      query: (newReferee) => ({
        url: `/personnel/references`,
        method: 'POST',
        body: newReferee,
      }),
    }),

    updateReferee: builder.mutation<Referee, Partial<Referee>>({
      query: ({id, ...update}) => ({
        url: `/personnel/references/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createBenefit: builder.mutation<Benefit, Partial<Benefit>>({
      query: (newBenefit) => ({
        url: `/personnel/benefit`,
        method: 'POST',
        body: newBenefit,
      }),
    }),

    updateBenefit: builder.mutation<Benefit, Partial<Benefit>>({
      query: ({id, ...update}) => ({
        url: `/personnel/benefit/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createDocument: builder.mutation<Document, Partial<Document>>({
      query: (newDocument) => ({
        url: `/personnel/docs`,
        method: 'POST',
        body: newDocument,
      }),
    }),

    updateDocument: builder.mutation<Document, Partial<Document>>({
      query: ({id, ...update}) => ({
        url: `/personnel/docs/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    createMembership: builder.mutation<Membership, Partial<Membership>>({
      query: (newMembership) => ({
        url: `/personnel/membership`,
        method: 'POST',
        body: newMembership,
      }),
    }),

    updateMembership: builder.mutation<Membership, Partial<Membership>>({
      query: ({id, ...update}) => ({
        url: `/personnel/membership/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useCreatePaymentAccountMutation,
  useUpdatePaymentAccountMutation,
  useCreateBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
  useCreateContractMutation,
  useUpdateContractMutation,
  useCreateWorkHistoryMutation,
  useUpdateWorkHistoryMutation,
  useCreateEducationMutation,
  useUpdateEducationMutation,
  useCreateRefereeMutation,
  useUpdateRefereeMutation,
  useCreateBenefitMutation,
  useUpdateBenefitMutation,
  useCreateMembershipMutation,
  useUpdateMembershipMutation,
} = employeeApi;
