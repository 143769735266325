import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { GetOfficePayload } from "../types/office";
import { Office } from "../types";

export const officeApi = createApi({
  reducerPath: 'officesApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getOffices: builder.query<Office[], { [key: string]: string }>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        Object.entries(params || {}).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            searchParams.append(key, value);
          }
        });

        return {
          url: `/provisioner/offices${searchParams.toString() ? `?${searchParams.toString()}` : ''}`
        };
      },
    }),

    getOfficeById: builder.query<Office, GetOfficePayload>({
      query: ({id}) => `/provisioner/offices/${id}`,
    }),

    createOffice: builder.mutation<Office, Partial<Office>>({
      query: (newOffice) => ({
        url: `/provisioner/offices`,
        method: 'POST',
        body: newOffice,
      }),
    }),

    updateOffice: builder.mutation<Office, Partial<Office>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/offices/${id}`,
        method: 'PUT',
        body: update,
      }),
    }),

    deleteOffice: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/offices/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetOfficesQuery,
  useGetOfficeByIdQuery,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation
} = officeApi;
