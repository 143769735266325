import UserList from "../pages";
import AddUpdateUser from "../pages/add_update";
import { RouteObject } from "../../../common/types";

const userRoutes: Array<RouteObject> = [
  {
    path: '/apps-users-list',
    component: UserList,
    children: [
      {
        path: 'add',
        component: AddUpdateUser
      },
      {
        path: 'update/:id',
        component: AddUpdateUser
      },
    ]
  },
];

export default userRoutes;
