import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { getLoggedInUser } from "../features/auth/slices/auth_slice_api";
import { AuthData } from "../features/auth/types";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuth = () => {
  // @ts-ignore
  const user = useSelector<RootState, AuthData | null>(getLoggedInUser);

  return {
    user,
    isAuthenticated: !!user,
    accessToken: !!user?.accessToken,
    refreshToken: !!user?.refreshToken,
  };
};