import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";
import { Project } from "../types";
import { GetProjectPayload, ProjectAssignment } from "../types/project";

export const projectApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getProjects: builder.query<Project[], void>({
      query: () => `/provisioner/projects`,
    }),

    getProjectById: builder.query<Project, GetProjectPayload>({
      query: ({id}) => `/provisioner/projects/${id}`,
    }),

    createProject: builder.mutation<Project, Partial<Project>>({
      query: (newProject) => ({
        url: `/provisioner/projects`,
        method: 'POST',
        body: newProject,
      }),
    }),

    assignProject: builder.mutation<any, ProjectAssignment>({
      query: (newProject) => ({
        url: `/provisioner/projects/assign`,
        method: 'POST',
        body: newProject,
      }),
    }),

    updateProject: builder.mutation<Project, Partial<Project>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/projects/${id}`,
        method: 'PUT',
        body: update,
      }),
    }),

    deleteProject: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/projects/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useAssignProjectMutation,
  useDeleteProjectMutation
} = projectApi;
