import { createApi } from "@reduxjs/toolkit/query/react";
import { GetTenantPayload, Tenant } from "../types";
import { rtkBaseQuery } from "../../../common/utils/rtk_base_query";

export const tenantApi = createApi({
  reducerPath: 'tenantsApi',
  baseQuery: rtkBaseQuery,
  endpoints: builder => ({
    getTenants: builder.query<Tenant[], void>({
      query: () => `/provisioner/tenants`,
    }),

    getTenantById: builder.query<Tenant, GetTenantPayload>({
      query: ({id}) => `/provisioner/tenants/${id}`,
    }),

    createTenant: builder.mutation<Tenant, Partial<Tenant>>({
      query: (newTenant) => ({
        url: `/provisioner/tenants`,
        method: 'POST',
        body: newTenant,
      }),
    }),

    updateTenant: builder.mutation<Tenant, Partial<Tenant>>({
      query: ({id, ...update}) => ({
        url: `/provisioner/tenants/${id}`,
        method: 'PATCH',
        body: update,
      }),
    }),

    deleteTenant: builder.mutation<{ success: boolean, id: string }, string>({
      query: (id) => ({
        url: `/provisioner/tenants/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetTenantsQuery,
  useGetTenantByIdQuery,
  useCreateTenantMutation,
  useUpdateTenantMutation,
  useDeleteTenantMutation
} = tenantApi;
