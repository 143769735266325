import React, { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Tenant } from "../types";
import Modal from "../../../common/components/Modal";

interface AddUpdateProps {
  isOpen: boolean;
  tenant?: Tenant;
  onClose: () => void;
  onSubmit: (data: Partial<Tenant>) => void;
}

/**
 * @function
 * @name AddUpdateTenant
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateTenant: React.FC<AddUpdateProps> = ({isOpen, tenant, onClose, onSubmit}) => {
  console.log("AddUpdateTenant", tenant);
  const [eventData, setEventData] = useState<Partial<Tenant>>(tenant || {});

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: eventData?.name ?? '',
      code: eventData?.code ?? '',
      status: eventData?.status ?? '',
      superTenant: eventData?.superTenant ?? false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      code: Yup.string().required("Please Enter Code"),
      superTenant: Yup.boolean().required("Please Select whether Tenant is Super Tenant"),
    }),
    onSubmit: (
      values: Partial<Tenant>,
      {setSubmitting}: FormikHelpers<Partial<Tenant>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setEventData({...eventData, [name]: value});
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{tenant ? "Edit Tenant" : "Add Tenant"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="code" className="inline-block mb-2 text-base font-medium">
              Code
            </label>
            <input type="text"
                   id="code"
                   name="code"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   onChange={validation.handleChange}
                   value={validation.values.code || ""}
            />
            {validation.touched.code && validation.errors.code ? (
              <p className="text-red-400">{validation.errors.code}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="nameInput"
                   className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input type="text"
                   id="nameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter name"
                   name="name"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
            />
            {validation.touched.name && validation.errors.name ? (
              <p className="text-red-400">{validation.errors.name}</p>
            ) : null}
          </div>
          <div className="flex items-center">
            <div
              className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
              <input type="checkbox" name="superTenant" id="superTenant"
                     className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-sm appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-red-500 dark:checked:border-red-500 arrow-none"
                     defaultChecked={validation.values.superTenant ?? false} onChange={handleChange}
              />
              <label htmlFor="superTenant"
                     className="block h-5 overflow-hidden duration-300 ease-linear border rounded-sm cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-red-500 peer-checked/published:border-red-500"></label>
            </div>
            <label htmlFor="superTenant" className="inline-block text-base font-medium cursor-pointer">
              Super Tenant
            </label>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" data-modal-close="addDocuments"
                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit"
                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {tenant ? "Update Tenant" : "Add Tenant"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateTenant;
