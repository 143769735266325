import React, { useMemo, useState } from 'react';
import BreadCrumb from 'common/BreadCrumb';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import TableContainer from 'common/TableContainer';
import Select from 'react-select';

// Icons
import { CheckCircle, Eye, Pencil, Plus, Search, Trash2, X } from 'lucide-react';
import DeleteModal from 'common/DeleteModal';

// Images
// react-redux
// Formik
import { toast, ToastContainer } from 'react-toastify';
import WpLoader from "../../../common/components/loader";
import {
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionsQuery,
  useUpdatePermissionMutation
} from "../slices/permission_slice_api";
import { Permission } from "../types";
import { PermissionStatuses } from "../db";
import AddUpdatePermission from "./add_update";

const PermissionList = () => {
  const navigate = useNavigate();
  const {data: permissionList = [], isLoading, refetch} = useGetPermissionsQuery({});
  const [createPermission] = useCreatePermissionMutation();
  const [updatePermission] = useUpdatePermissionMutation();
  const [deletePermission] = useDeletePermissionMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<Permission | undefined>(undefined);

  const handleCreate = () => {
    setSelectedPermission(undefined);
    setIsModalOpen(true);
  };

  const handleEdit = (permission: Permission) => {
    console.log("Original permission: ", permission);
    setSelectedPermission(permission);
    console.log("Selected permission: ", selectedPermission);
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    deletePermission(selectedPermission!.id);
    setDeleteModal(false);
  };

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
  };

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ['name', 'status'];
    // filterDataBySearch(permissionList, search, keysToSearch, setPermission);
  };

  // columns
  const Status = ({status}: any) => {
    if (status) {
      return (<span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
                <CheckCircle className="size-3 mr-1.5"/>YES</span>
      );
    }

    return (<span
        className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            <X className="size-3 mr-1.5"/>NO</span>
    );

  };

  const handleSubmit = async (data: Partial<Permission>) => {
    try {
      if (selectedPermission) {
        await updatePermission({...selectedPermission, ...data}).unwrap();
      } else {
        await createPermission(data).unwrap();
      }

      setIsModalOpen(false);
      toast.success(`Permission successfully ${selectedPermission ? 'updated' : 'created'}`);

      refetch();
    } catch (error: any) {
      toast.error(`Failed to ${selectedPermission ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };


  function retrieveData() {
    return permissionList && permissionList.length > 0 ?
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(permissionList || [])}
        customPageSize={10}
        divclassName="-mx-5 -mb-5 overflow-x-auto"
        tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
        theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
        PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
      />
      :
      (
        <div className="noresult">
          <div className="py-6 text-center">
            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
            <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
            <p className="mb-0 text-slate-500 dark:text-zink-200">
              No Data Found.
            </p>
          </div>
        </div>
      )
  }

  const columns = useMemo(() => [
      {
        header: (
          <div className="flex items-center h-full">
            <input id="CheckboxAll"
                   className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                   type="checkbox"/>
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input id="Checkbox1"
                     className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                     type="checkbox"/>
            </div>
          );
        }
      },
      {
        header: "Module",
        accessorKey: "moduleName",
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: "Is Active",
        accessorKey: "active",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Status status={cell.getValue()}/>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-permissions-list/update/${cell.row.original.id}`}
              onClick={() => {
                handleEdit(cell.row.original)
              }}>
              <Eye className="inline-block size-4"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/apps-permissions-list/update/${cell.row.original.id}`}
              onClick={() => {
                handleEdit(cell.row.original)
              }}
            >
              <Pencil className="text-blue-700 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to="#!" onClick={() => {
              const orderData = cell.row.original;
              onClickDelete(orderData);
            }}>
              <Trash2 className="text-red-400 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
      // setPermission(permissionList);
    } else {
      const filteredPermissions = permissionList.filter((data: any) => data.status === selectedOption.value);
      // setPermission(filteredPermissions);
    }
    // TODO Add implementation for fetching using the redux
  };

  return (
    <React.Fragment>
      <Outlet/>
      <BreadCrumb title='Permissions List' pageTitle='Permissions'/>
      <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
      <ToastContainer closeButton={false} limit={1}/>
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="permissionsTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Permissions</h6>
                <div className="shrink-0">
                  <Link data-modal-target="defaultModal"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                        to="/apps-permissions-list/add">
                    <button type="button"
                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                            onClick={handleCreate}
                    >
                      <Plus className="inline-block size-2"/>
                      <span className="align-middle">Add Permission</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input type="text"
                           className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                           placeholder="Search for name, email, phone number etc..."
                           autoComplete="off" onChange={(e) => filterSearchData(e)}/>
                    <Search
                      className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                  </div>
                  <div className="xl:col-span-2">
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      options={PermissionStatuses}
                      isSearchable={false}
                      defaultValue={PermissionStatuses[0]}
                      onChange={(event: any) => handleChange(event)}
                      id="choices-single-default"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {isLoading ? <WpLoader/> : retrieveData()}
            </div>
          </div>
        </div>
      </div>

      {/* Permission Modal  */}
      <AddUpdatePermission
        key={selectedPermission?.id || 'new'}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedPermission(undefined);
          navigate('.')
        }}
        onSubmit={handleSubmit}
        permission={selectedPermission}
      />
    </React.Fragment>
  );
};

export default PermissionList;
