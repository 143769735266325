import ProjectList from "../pages";
import AddUpdateProject from "../pages/add_update";
import { RouteObject } from "../../../common/types";

const projectRoutes: Array<RouteObject> = [
  {
    path: '/apps-projects-list',
    component: ProjectList,
    children: [
      {
        path: '/apps-projects-list/add',
        component: AddUpdateProject
      },
      {
        path: '/apps-projects-list/update/:id',
        component: AddUpdateProject
      },
    ]
  },
];

export default projectRoutes;
