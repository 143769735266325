import * as Yup from "yup";
import { User } from "../types";
import Select from "react-select";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import Modal from "../../../common/components/Modal";
import { useGetRolesQuery } from "../../role/slices/role_slice_api";
import { useGetTenantsQuery } from "../../tenant/slices/tenant_api_slice";
import { useGetOfficesQuery } from "../../office/slices/office_slice_api";

interface AddUpdateProps {
  isOpen: boolean;
  user?: User;
  onClose: () => void;
  onSubmit: (data: Partial<User>) => void;
}

/**
 * @function
 * @name AddUpdateUser
 * @description add/update user
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateUser: React.FC<AddUpdateProps> = ({isOpen, user, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<User>>(user || {});
  const {data: roles, isLoading: isRolesLoading} = useGetRolesQuery();
  const {data: tenants, isLoading: isTenantsLoading} = useGetTenantsQuery();
  const {data: offices, isLoading: isOfficeLoading} = useGetOfficesQuery({});

  const [selectedTenant, setSelectedTenant] = useState<any>(user?.tenantId ?? [])
  const [selectedOffice, setSelectedOffice] = useState<any>(user?.officeId ?? []);
  const [selectedRole, setSelectedRole] = useState<any>(user?.roleId ?? []);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: eventData?.id ?? '',
      tenantId: eventData?.tenantId ?? '',
      officeId: eventData?.officeId ?? '',
      roleId: eventData?.roleId ?? '',
      fullName: eventData?.fullName ?? '',
      userName: eventData?.userName ?? '',
      status: eventData?.status ?? '',
    },
    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      tenantId: Yup.string().required("Please Choose Tenant"),
      officeId: Yup.string().required("Please Choose Office"),
      roleId: Yup.string().required("Please Choose Role"),
      fullName: Yup.string().required("Please Enter Full Name"),
      username: Yup.string().required("Please Enter Username"),
    }),
    onSubmit: (values: Partial<User>, {setSubmitting}: FormikHelpers<Partial<User>>) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleTenantChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setSelectedTenant(tenants);
    } else {
      const filteredTenants = tenants!.filter((data: any) => data.id === selectedOption.value);
      setSelectedTenant(filteredTenants);
    }
  };

  const handleOfficeChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Office' || selectedOption.value === 'Hidden') {
      setSelectedOffice(offices);
    } else {
      const filteredOffices = offices!.filter((data: any) => data.id === selectedOption.value);
      setSelectedOffice(filteredOffices);
    }
  };

  const handleRoleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Role' || selectedOption.value === 'Hidden') {
      setSelectedRole(roles);
    } else {
      const filteredRoles = roles!.filter((data: any) => data.id === selectedOption.value);
      setSelectedRole(filteredRoles);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setEventData({...eventData, [name]: value});
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{user ? "Edit User" : "Add User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.values.tenantId = selectedTenant[0].id;
            validation.values.officeId = selectedOffice[0].id;
            validation.values.roleId = selectedRole[0].id;

            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="tenantSelect"
                   className="inline-block mb-2 text-base font-medium">
              Tenant
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={tenants}
              isSearchable={false}
              onChange={(event: any) => handleTenantChange(event)}
              id="tenantSelect"
              required={true}
              isLoading={isTenantsLoading}
            />
            {validation.touched.tenantId && validation.errors.tenantId ? (
              <p className="text-red-400">{validation.errors.tenantId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="officeSelect"
                   className="inline-block mb-2 text-base font-medium">
              Office
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={offices}
              isSearchable={false}
              onChange={(event: any) => handleOfficeChange(event)}
              id="officeSelect"
              required={true}
              isLoading={isOfficeLoading}
            />
            {validation.touched.officeId && validation.errors.officeId ? (
              <p className="text-red-400">{validation.errors.officeId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="roleSelect"
                   className="inline-block mb-2 text-base font-medium">
              Role
            </label>

            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={roles}
              isSearchable={false}
              onChange={(event: any) => handleRoleChange(event)}
              id="roleSelect"
              required={true}
              isLoading={isRolesLoading}
            />
            {validation.touched.roleId && validation.errors.roleId ? (
              <p className="text-red-400">{validation.errors.roleId}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="fullNameInput"
                   className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input type="text" id="fullNameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter name"
                   name="name"
                   onChange={validation.handleChange}
                   value={validation.values.name || ""}
            />
            {validation.touched.fullName && validation.errors.fullName ? (
              <p className="text-red-400">{validation.errors.fullName}</p>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="userNameInput"
                   className="inline-block mb-2 text-base font-medium">
              Username
            </label>
            <input type="text" id="userNameInput"
                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                   placeholder="Enter username"
                   name="userName"
                   onChange={validation.handleChange}
                   value={validation.values.userName || ""}
                   required={false}
            />
            {validation.touched.userName && validation.errors.userName ? (
              <p className="text-red-400">{validation.errors.userName}</p>
            ) : null}
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" data-modal-close="addDocuments"
                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit"
                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {user ? "Update User" : "Add User"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateUser;
