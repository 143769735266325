import React, { useState } from 'react';
import Modal from "../../../common/components/Modal";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Employee } from "../types";
import { EmploymentStatuses, Genders, MaritalStatuses, Titles } from "../db";
import { useGetTenantsQuery } from "../../tenant/slices/tenant_api_slice";
import { Tenant } from "../../tenant/types";
import { GenericDataList } from "../../../common/types";

interface ProjectLinkProps {
  isOpen: boolean;
  employee?: Employee;
  onClose: () => void;
  onSubmit: (data: Partial<Employee>) => void;
}

/**
 * @function
 * @name ProjectLink
 * @description add/update for employee project link
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const ProjectLink: React.FC<ProjectLinkProps> = ({isOpen, employee, onClose, onSubmit}) => {
  const [eventData, setEventData] = useState<Partial<Employee>>(employee || {});
  const {data: tenants = [], isLoading: isTenantsLoading} = useGetTenantsQuery();
  const [selectedTenant, setSelectedTenant] = useState<any>(employee?.tenantId ?? []);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: eventData?.title ?? '',
      firstName: eventData?.firstName ?? '',
      otherNames: eventData?.otherNames ?? '',
      sirName: eventData?.sirName ?? '',
      gender: eventData?.gender ?? '',
      maritalStatus: eventData?.maritalStatus ?? '',
      tenantId: eventData?.tenantId ?? '',
      birthDate: eventData?.birthDate ?? '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      firstName: Yup.string().required("Please Enter First Name"),
      sirName: Yup.string().required("Please Enter Sir Name"),
      otherNames: Yup.string().optional(),
      gender: Yup.string().required("Please Enter Gender"),
      tenantId: Yup.string().required("Please Enter Tenant"),
      birthDate: Yup.string().required("Please Select Birth Date"),
      maritalStatus: Yup.string().optional(),
    }),
    onSubmit: (
      values: Partial<Employee>,
      {setSubmitting}: FormikHelpers<Partial<Employee>>
    ) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setSelectedTenant(tenants);
    } else {
      const filteredTenants = tenants.filter((data: any) => data.id === selectedOption.value);
      setSelectedTenant(filteredTenants);
    }
  };

  if (!isOpen) return null;

  const getTenantsSelection = (tenants: Tenant[]): GenericDataList[] => {
    return tenants.map(tenant => ({
      label: `${tenant.code} - ${tenant.name}`,
      value: tenant.id,
    }));
  }

  return (
    <Modal show={isOpen} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {employee ? "Edit Employee" : "Add Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          className="create-form"
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.values.tenantId = selectedTenant[0].id;
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="titleSelect"
                     className="inline-block mb-2 text-base font-medium">
                Title
              </label>
              <Select
                options={Titles}
                id="titleSelect"
                name="title"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "title",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-400">{validation.errors.title}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeFirstNameInput"
                     className="inline-block mb-2 text-base font-medium">First Name</label>
              <input type="text" id="employeeFirstNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="First name"
                     name="firstName"
                     onChange={validation.handleChange}
                     value={validation.values.firstName || ""}
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <p className="text-red-400">{validation.errors.firstName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeSirNameInput"
                     className="inline-block mb-2 text-base font-medium">Sir Name</label>
              <input type="text" id="employeeSirNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Sir name"
                     name="sirName"
                     onChange={validation.handleChange}
                     value={validation.values.sirName || ""}
              />
              {validation.touched.sirName && validation.errors.sirName ? (
                <p className="text-red-400">{validation.errors.sirName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeOtherNameInput"
                     className="inline-block mb-2 text-base font-medium">Other Names</label>
              <input type="text" id="employeeOtherNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Other name"
                     name="otherNames"
                     onChange={validation.handleChange}
                     value={validation.values.otherNames || ""}
              />
              {validation.touched.otherNames && validation.errors.otherNames ? (
                <p className="text-red-400">{validation.errors.otherNames}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="birthDate" className="inline-block mb-2 text-base font-medium">Birth Date</label>
              <input type="date"
                     id="birthDate"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Select Birth Date"
                     name="birthDate"
                     onChange={(event: any) => {
                       validation.handleChange({
                         target: {
                           name: "birthDate",
                           value: event.target.value,
                         },
                       });
                       validation.values.birthDate = event.target.value
                     }}
                     value={validation.values.birthDate || ""}
              />
              {validation.touched.birthDate && validation.errors.birthDate ? (
                <p className="text-red-400">{validation.errors.birthDate}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="genderSelect"
                     className="inline-block mb-2 text-base font-medium">Gender</label>
              <Select
                options={Genders}
                id="genderSelect"
                name="gender"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "gender",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.gender && validation.errors.gender ? (
                <p className="text-red-400">{validation.errors.gender}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="maritalStatusSelect"
                     className="inline-block mb-2 text-base font-medium">
                Marital Status
              </label>
              <Select
                options={MaritalStatuses}
                id="maritalStatusSelect"
                name="maritalStatus"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "maritalStatus",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.maritalStatus && validation.errors.maritalStatus ? (
                <p className="text-red-400">{validation.errors.maritalStatus}</p>
              ) : null}
            </div>

            <div className="xl:col-span-12 mb-3">
              <label htmlFor="tenantSelect"
                     className="inline-block mb-2 text-base font-medium">
                Tenant
              </label>

              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={getTenantsSelection(tenants)}
                isSearchable={false}
                onChange={(event: any) => handleChange(event)}
                id="tenantSelect"
                required={true}
                isLoading={isTenantsLoading}
              />
              {validation.touched.tenantId && validation.errors.tenantId ? (
                <p className="text-red-400">{validation.errors.tenantId}</p>
              ) : null}
            </div>

            {employee &&
              <div className="xl:col-span-12">
                <label htmlFor="employmentStatusSelect"
                       className="inline-block mb-2 text-base font-medium">
                  Employment Status
                </label>
                <Select
                  options={EmploymentStatuses}
                  id="employmentStatusSelect"
                  name="employmentStatus"
                  onChange={(selectedOption: any) => {
                    validation.handleChange({
                      target: {
                        name: "employmentStatus",
                        value: selectedOption.value,
                      },
                    });
                  }}
                />
                {validation.touched.employmentStatus && validation.errors.employmentStatus ? (
                  <p className="text-red-400">{validation.errors.employmentStatus}</p>
                ) : null}
              </div>
            }

          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addEmployeeModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={onClose}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {employee ? "Update Employee" : "Add Employee"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ProjectLink;
