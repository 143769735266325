import React, { useState } from "react";
import { MoveRight, Pencil } from "lucide-react";

import { Address, ContactInfo, Employee, PaymentAccount } from "../types";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  useCreateAddressMutation,
  useCreateContactMutation,
  useCreatePaymentAccountMutation,
  useUpdateAddressMutation,
  useUpdateContactMutation,
  useUpdateEmployeeMutation,
  useUpdatePaymentAccountMutation
} from "../slices/employee_slice_api";
import { toast } from "react-toastify";
import AddUpdateEmployee from "../pages/add_update";
import AddUpdateAccount from "../pages/account_add_update";
import AddUpdateAddress from "../pages/address_add_update";
import AddUpdateContact from "../pages/contact_add_update";

interface TableData {
  label: string;
  value?: string;
  link?: boolean
}

interface OverviewTabsProps {
  employee?: Employee;
  refetch?: () => void;
}

const Overview: React.FC<OverviewTabsProps> = ({employee, refetch}) => {
  const navigate = useNavigate();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const [createPaymentAccount] = useCreatePaymentAccountMutation();
  const [updatePaymentAccount] = useUpdatePaymentAccountMutation();

  const [createAddress] = useCreateAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();

  const [createContact] = useCreateContactMutation();
  const [updateContact] = useUpdateContactMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(employee);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<PaymentAccount | undefined>(undefined);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactInfo | undefined>(undefined);

  const personalInfo: TableData[] = [
    {label: "Title", value: employee?.title ?? 'N/A'},
    {label: "First Name", value: employee?.firstName ?? 'N/A'},
    {label: "Last Name", value: employee?.sirName ?? 'N/A'},
    {label: "Other Names", value: employee?.otherNames ?? 'N/A'},
    {label: "Designation", value: employee?.jobTitle ?? 'N/A'},
    {label: "Birth of Date", value: employee?.birthDate ?? 'N/A'},
    {label: "Gender", value: employee?.gender ?? 'N/A'},
    {label: "Marital Status", value: employee?.maritalStatus ?? 'N/A'},
    {label: "Employee ID", value: employee?.employeeId ?? 'N/A'},
    {label: "Joining Date", value: employee?.joinDate ?? 'N/A'},
    {label: "Record Status", value: employee?.status ?? 'N/A'},
    {label: "Employment Status", value: employee?.employmentStatus ?? 'N/A'},
  ];

  const handleUpdateInfo = () => {
    navigate(`/apps-employees-view/${employee!.id}/update`);

    handleEdit(employee!);
  };

  const handleEdit = (employee: Employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const handlePaymentAccountCreate = () => {
    navigate(`/apps-employees-view/${employee!.id}/accounts`);
    setSelectedPaymentAccount(undefined);
    setIsPaymentModalOpen(true);
  };

  const handlePaymentAccountEdit = (account: PaymentAccount) => {
    navigate(`/apps-employees-view/${employee!.id}/accounts/${account.id}`);

    setSelectedPaymentAccount(account);
    setIsPaymentModalOpen(true);
  };

  const handleAddressCreate = () => {
    navigate(`/apps-employees-view/${employee!.id}/addresses`);
    setSelectedAddress(undefined);
    setIsAddressModalOpen(true);
  };

  const handleAddressEdit = (address: Address) => {
    setSelectedAddress(address);
    setIsAddressModalOpen(true);
  };

  const handleContactCreate = () => {
    navigate(`/apps-employees-view/${employee!.id}/contacts`);
    setSelectedContact(undefined);
    setIsContactModalOpen(true);
  };

  const handleContactEdit = (contactInfo: ContactInfo) => {
    setSelectedContact(contactInfo);
    setIsContactModalOpen(true);
  };

  const handlePersonalInfoSubmit = async (data: Partial<Employee>) => {
    try {
      await updateEmployee({...selectedEmployee, ...data}).unwrap();

      setIsModalOpen(false);
      toast.success(`Employee successfully updated`);
      navigate('.')
    } catch (error: any) {
      toast.error(`Failed to ${selectedEmployee ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };

  const handlePaymentAccountSubmit = async (data: Partial<PaymentAccount>) => {
    try {
      data = {
        ...data,
        employeeId: employee!.id,
      };

      if (selectedPaymentAccount) {
        await updatePaymentAccount({...selectedPaymentAccount, ...data}).unwrap();
      } else {
        await createPaymentAccount(data).unwrap();
      }

      setIsPaymentModalOpen(false);
      toast.success(`PaymentAccount successfully ${selectedPaymentAccount ? 'updated' : 'created'}`);

      navigate('.');
    } catch (error: any) {
      toast.error(`Failed to ${selectedPaymentAccount ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };

  const handleAddressSubmit = async (data: Partial<Address>) => {
    try {
      data = {
        ...data,
        employeeId: employee!.id,
      };

      if (selectedAddress) {
        await updateAddress({...selectedAddress, ...data}).unwrap();
      } else {
        await createAddress(data).unwrap();
      }

      setIsAddressModalOpen(false);
      toast.success(`Address successfully ${selectedAddress ? 'updated' : 'created'}`);

      navigate('.');
    } catch (error: any) {
      toast.error(`Failed to ${selectedAddress ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };


  const handleContactSubmit = async (data: Partial<ContactInfo>) => {
    try {
      data = {
        ...data,
        employeeId: employee!.id,
      };

      if (selectedContact) {
        await updateContact({...selectedContact, ...data}).unwrap();
      } else {
        await createContact(data).unwrap();
      }

      setIsContactModalOpen(false);
      toast.success(`Contact successfully ${selectedContact ? 'updated' : 'created'}`);

      if (refetch) {
        refetch();
      }

      navigate('.');
    } catch (error: any) {
      toast.error(`Failed to ${selectedContact ? 'update' : 'create'} tenant: ${error!.message}`);
    }
  };


  return (
    <React.Fragment>
      <Outlet/>
      <div className="grid grid-cols-1 gap-x-5 lg:grid-cols-2 2xl:grid-cols-2">

        <div className="card">
          <div className="card-body">
            <h6 className="mb-4 text-15">Personal Information</h6>
            <div className="overflow-x-auto">
              <table className="w-full ltr:text-left rtl:ext-right">
                <tbody>
                {
                  (personalInfo || [])?.map((item: TableData, index: number) => (
                    <tr key={index}>
                      <th className="py-2 font-semibold ps-0"
                          scope="row">{item.label}</th>
                      <td className="py-2 text-right text-slate-500 dark:text-zink-200">
                        {item.link ? <a href="http://watupro.tz/" rel="noopener"
                                        className="text-custom-500">www.watupro.tz</a> : item.value}
                      </td>
                    </tr>

                  ))
                }
                </tbody>
              </table>
            </div>
            <div className="mt-3 text-center">
              <a
                href="#!"
                className="inline-block underline text-custom-500"
                onClick={handleUpdateInfo}
              >
                Update Info <MoveRight className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight>
              </a>
            </div>
          </div>
        </div>

        {/*Personal Information */}
        <div>
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">Contact Details</h6>
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Type
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Details
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Emergency
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {employee &&
                    (employee.contacts || [])?.map((item: ContactInfo, index: number) => (
                      <tr key={employee.id + '_' + index}>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.type}</td>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.value}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.emergency ? 'YES' : 'NO'}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex flex-row p-2">
                          <span
                            className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                            {item.status}
                          </span>

                          <Link
                            className="ml-2.5 flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-yellow-300 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                            to={`/apps-employees-view/${employee.id}/contacts/${item.id}`}
                            onClick={() => handleContactEdit(item)}
                          >
                            <Pencil className="text-yellow-700 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
                          </Link>
                        </td>
                      </tr>

                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-center">
                <a href="#!"
                   className="inline-block underline text-custom-500"
                   onClick={handleContactCreate}
                >
                  Add New Contact <MoveRight className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight></a>
              </div>
            </div>
          </div>
          {/* Contact Details*/}

          <div className="card">
            <div className="card-body">
              <h6 className="mb-3 text-15">Addresses</h6>
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Type
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Details
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Emergency
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {employee &&
                    (employee.addresses || [])?.map((item: Address, index: number) => (
                      <tr key={employee.id + '_' + index}>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.type}</td>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.value}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.emergency ? 'YES' : 'NO'}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex flex-row p-2">
                          <span
                            className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                            {item.status}
                          </span>
                          <Link
                            className="ml-2.5 flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-yellow-300 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                            to={`/apps-employees-view/${employee.id}/addresses/${item.id}`}
                            onClick={() => handleAddressEdit(item)}
                          >
                            <Pencil className="text-yellow-700 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
                          </Link>
                        </td>
                      </tr>

                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-center">
                <a href="#!"
                   className="inline-block underline text-custom-500"
                   onClick={handleAddressCreate}
                >
                  Add New Address <MoveRight className="inline-block size-4 ml-2 rtl:-rotate-180">
                </MoveRight>
                </a>
              </div>
            </div>
          </div>
          {/*  Addresses */}

          <div className="card">
            <div className="card-body">
              <h6 className="mb-3 text-15">Accounts</h6>

              <div className="flex flex-col gap-3">
                {employee &&
                  (employee.paymentAccounts || [])?.map((item: PaymentAccount, index: number) => (
                    <a key={employee.id + '_' + index}
                       href="#!"
                       className="flex items-center gap-3 p-2 border rounded-md border-slate-200 dark:border-zink-500"
                       onClick={() => handlePaymentAccountEdit(item)}
                    >
                      <div className="flex items-center justify-center size-12 rounded-md shrink-0 mr-3">
                        <h5 className="mb-1 text-15 uppercase">{item.accountType}</h5>
                      </div>
                      <div className="grow">
                        <h5 className="mb-1 text-15 ">{item.accountNumber}</h5>
                        <p className="text-sm text-slate-500 dark:text-zink-200">
                          {item.provider} : {item.accountName}
                        </p>
                      </div>
                      <div className="flex flex-row gap-3">
                        <span
                          className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                              {item.status}
                        </span>
                      </div>
                    </a>
                  ))
                }
              </div>
              <div className="mt-3 text-center">
                <a href="#!"
                   className="inline-block underline text-custom-500"
                   onClick={handlePaymentAccountCreate}
                >
                  Add New Account <MoveRight className="inline-block size-4 ml-2 rtl:-rotate-180">
                </MoveRight>
                </a>
              </div>
            </div>
          </div>
          {/*  Payment Accounts */}

        </div>
      </div>

      <AddUpdateEmployee
        key={selectedEmployee?.id || 'update'}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedEmployee(undefined);
          navigate('.')
        }}
        onSubmit={handlePersonalInfoSubmit}
        employee={employee}
      />

      <AddUpdateAccount
        key={selectedEmployee?.id + '_paymentAccount' || 'newPaymentAccount'}
        isOpen={isPaymentModalOpen}
        onClose={() => {
          setIsPaymentModalOpen(false);
          setSelectedPaymentAccount(undefined);
          navigate('.')
        }}
        paymentAccount={selectedPaymentAccount}
        onSubmit={handlePaymentAccountSubmit}
      />

      <AddUpdateAddress
        key={selectedEmployee?.id + '_address' || 'newAddress'}
        isOpen={isAddressModalOpen}
        onClose={() => {
          setIsAddressModalOpen(false);
          setSelectedAddress(undefined);
          navigate('.')
        }}
        address={selectedAddress}
        onSubmit={handleAddressSubmit}
      />

      <AddUpdateContact
        key={selectedEmployee?.id + '_contact' || 'newContact'}
        isOpen={isContactModalOpen}
        onClose={() => {
          setIsContactModalOpen(false);
          setSelectedContact(undefined);
          navigate('.')
        }}
        contactInfo={selectedContact}
        onSubmit={handleContactSubmit}
      />
    </React.Fragment>
  );
}

export default Overview;