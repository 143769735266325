import React from "react";
import { Dropdown } from "common/components/Dropdown";
import { Eye, FileEdit, MoreHorizontal, Trash2 } from "lucide-react";

import { Employee, Project } from "../types";
import { Outlet } from "react-router-dom";

interface ProjectTabProps {
  employee: Employee;
}

const Projects: React.FC<ProjectTabProps> = ({employee}) => {
  return (
    <React.Fragment>
      <Outlet/>
      <div className="flex items-center gap-3 mb-4">
        <h5 className="underline grow">Projects</h5>
        <div className="shrink-0">
          <button type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
            Add Project {employee.projects?.length}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-4">
        {employee && (employee.projects || [])?.map((project: Project) => (
          <div key={project.id} className="card">
            <div className="card-body">
              <div className="flex">
                <div className="grow">
                  <img src={project.imageSrc} alt="" className="h-11"/>
                </div>
                <div className="shrink-0">
                  <Dropdown className="relative">
                    <Dropdown.Trigger
                      className="flex items-center justify-center size-[37.5px] dropdown-toggle p-0 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50"
                      id={`projectDropdownmenu${project.id}`} data-bs-toggle="dropdown">
                      <MoreHorizontal className="size-4"></MoreHorizontal>
                    </Dropdown.Trigger>
                    <Dropdown.Content placement="right-end"
                                      className="absolute z-50 py-2 mt-1 text-left list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem]"
                                      aria-labelledby={`projectDropdownmenu${project.id}`}>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <Eye className="inline-block size-3 mr-1"></Eye> Overview
                        </a>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <FileEdit className="inline-block size-3 mr-1"></FileEdit> Edit
                        </a>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <Trash2 className="inline-block size-3 mr-1"></Trash2> Delete
                        </a>
                      </li>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
              </div>

              <div className="mt-4">
                <h6 className="mb-1 text-16"><a href="#!">{project.title}</a></h6>
                <p className="text-slate-500 dark:text-zink-200">{project.description}</p>
              </div>

              <div
                className="flex w-full gap-3 mt-6 text-center divide-x divide-slate-200 dark:divide-zink-500 rtl:divide-x-reverse">
                <div className="px-3 grow">
                  <h6 className="mb-1">{project.dueDate}</h6>
                  <p className="text-slate-500 dark:text-zink-200">Due Date</p>
                </div>
                <div className="px-3 grow">
                  <h6 className="mb-1">{project.budget}</h6>
                  <p className="text-slate-500 dark:text-zink-200">Budget</p>
                </div>
              </div>

              <div className="w-full h-1.5 mt-6 rounded-full bg-slate-100 dark:bg-zink-600">
                <div className={`h-1.5 rounded-full bg-${project.text}-500`}
                     style={{width: `${project.completionPercentage}%`}}></div>
              </div>
            </div>
          </div>
        ))}

        {employee.projects?.length === 0 && (
          <div className="card">
            <div className="card-body">
              <div className="flex">
                <div className="shrink-0">
                  <Dropdown className="relative">
                    <Dropdown.Trigger
                      className="flex items-center justify-center size-[37.5px] dropdown-toggle p-0 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50"
                      id={`projectDropdownmenu`} data-bs-toggle="dropdown">
                      <MoreHorizontal className="size-4"></MoreHorizontal>
                    </Dropdown.Trigger>
                    <Dropdown.Content placement="right-end"
                                      className="absolute z-50 py-2 mt-1 text-left list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem]"
                                      aria-labelledby={`projectDropdownmenu`}>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <Eye className="inline-block size-3 mr-1"></Eye> Overview
                        </a>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <FileEdit className="inline-block size-3 mr-1"></FileEdit> Edit
                        </a>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear bg-white text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500"
                          href="#!">
                          <Trash2 className="inline-block size-3 mr-1"></Trash2> Delete
                        </a>
                      </li>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
              </div>

              <div className="mt-4">
                <h6 className="mb-1 text-16"><a href="#!">{'project.title'}</a></h6>
                <p className="text-slate-500 dark:text-zink-200">{'project.description'}</p>
              </div>

              <div
                className="flex w-full gap-3 mt-6 text-center divide-x divide-slate-200 dark:divide-zink-500 rtl:divide-x-reverse">
                <div className="px-3 grow">
                  <h6 className="mb-1">{'project.dueDate'}</h6>
                  <p className="text-slate-500 dark:text-zink-200">Due Date</p>
                </div>
                <div className="px-3 grow">
                  <h6 className="mb-1">{'project.budget'}</h6>
                  <p className="text-slate-500 dark:text-zink-200">Budget</p>
                </div>
              </div>

              <div className="w-full h-1.5 mt-6 rounded-full bg-slate-100 dark:bg-zink-600">
                <div className={`h-1.5 rounded-full bg-${'project.text'}-500`}
                     style={{width: `${'project.completionPercentage'}%`}}></div>
              </div>
            </div>
          </div>

        )}
      </div>
    </React.Fragment>
  );
}

export default Projects;