export const VIEW_PERMISSION_LIST = "";
export const VIEW_PERMISSION = "";
export const UPDATE_PERMISSION_LIST = "";
export const DELETE_PERMISSION_LIST = "";
export const APPROVE_PERMISSION_LIST = "";

export const PermissionStatuses = [
  {value: 'Select Status', label: 'Select Status'},
  {value: true, label: 'Active'},
  {value: false, label: 'In Active'},
];
